import { ReactNode } from 'react';
import { IconifyIcon } from '@iconify/react';
// @mui
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
// hooks
import { ToastContainer } from 'react-toastify';
// theme
import { ColorSchema } from '../theme/themes';

type Props = {
  children: ReactNode;
};

const NotistackProvider = ({ children }: Props) => {
  const theme = useTheme();

  const isRTL = theme.direction === 'rtl';

  return (
    <>
      {/* <SnackbarStyles /> */}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={isRTL}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ width: 'auto' }}
      />
      {children}
    </>
  );
};

export default NotistackProvider;

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: IconifyIcon | string;
  color: ColorSchema;
};
