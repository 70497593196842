import { Color } from '@mui/material';

const GREY: Color = {
  50: '#FAFAFA',
  100: '#F4F5F5',
  200: '#EBEDEF',
  300: '#CAD0DB',
  400: '#99A0AD',
  500: '#78808F',
  600: '#525A66',
  700: '#3E4552',
  800: '#2E3640',
  900: '#232833',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default GREY;
