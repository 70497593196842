import axios from 'axios';
import { serialize } from 'object-to-formdata';
import { Pagination, QueryParams, Response } from '../@types/api';
import {
  ChangePointsApiBody,
  ClaimedReward,
  CompanyInfoFormValues,
  CompanyType,
  Customer,
  CustomerActivityTimelineApiType,
  CustomerFile,
  CustomerFormSchema,
  PersonalFormValues,
  Purchase,
  RFMPlot,
  SocialLinksFormValues,
  Transaction,
} from '../@types/customer';

import { SocialLinks } from '../components/SocialsButton';
import removeEmptyFields from '../utils/removeEmptyFields';

import API_ROUTES from '../api_endpints';
import api from '../utils/axios';

export const cancelTokenList = [
  {
    key: 'CustomersRFMDataCancelToken',
    token: axios.CancelToken.source(),
  },
];

export const CancelRequest = (key: string) => {
  cancelTokenList.find((obj) => {
    if (obj.key === key) {
      obj.token.cancel();
      obj.token = newCancelToken();
    }
  });
};

export const getCancelToken = (key: string) =>
  cancelTokenList.find((obj) => obj.key === key)?.token || newCancelToken();

export const newCancelToken = () => axios.CancelToken.source();
// customerApi.cancelTokens.CustomersRFMDataCancelToken.cancel('we need to cancel this request');
// customerApi.cancelTokens.CustomersRFMDataCancelToken = newCancelToken();

const getCustomerProfile = (uuid: string) =>
  api.get<Response<Customer>>(
    API_ROUTES.business.getCustomerDetail.concat(`${uuid}/`),
  );

const getCustomerActivityTimeline = (uuid: string) =>
  api.get<Response<CustomerActivityTimelineApiType>>(
    API_ROUTES.business.getCustomerActivityTimeline(uuid),
  );

const getCustomersList = async (params: QueryParams) =>
  await api.get<Response<Pagination<Customer>>>(
    API_ROUTES.business.customers.list,
    {
      params,
    },
  );

// const CustomersRFMDataCancelToken = new AbortController();
const getRFMChartData = async () => {
  const axiosAbortController = new AbortController();

  try {
    const response = await api.post<Response<RFMPlot[]>>(
      API_ROUTES.business.customers.rfm,
      {
        start_date: '2010-01-01',
        end_date: '2012-01-01',
      },
      {
        cancelToken: getCancelToken('CustomersRFMDataCancelToken').token,
      },
    );

    return response;
  } catch (error) {
    // Handle error here
    console.log(error);
  }
};

const getCustomerPurchaseOverview = async (
  params: QueryParams,
  id: string | number,
) =>
  await api.get<Response<Pagination<Purchase>>>(
    API_ROUTES.business.getCustomerPurchase(id),
    {
      params,
    },
  );

const getCustomerTransactionsList = async (
  params: QueryParams,
  id: string | number,
) =>
  await api.get<Response<Pagination<Transaction>>>(
    API_ROUTES.business.getCustomerTransactionsList(id),
    { params },
  );

const confirmTransaction = async (id: string | number) =>
  await api.post<Response>(API_ROUTES.business.confirmTransaction(id));

const getCustomerClaimedRewadrs = async (
  params: QueryParams,
  id: string | number,
) =>
  await api.get<Response<Pagination<ClaimedReward>>>(
    API_ROUTES.business.getClaimedRewards(id),
    {
      params,
    },
  );

const getCustomerReferrals = async (params: QueryParams, id: string) =>
  await api.get<Response<Pagination<ClaimedReward>>>(
    API_ROUTES.business.getCustomerRefferals(id),
    {
      params,
    },
  );

const CreateNew = async (customer: Customer) => {
  const formData = new FormData();
  removeEmptyFields(customer);

  // if (customer.social_media) {
  // 	const keys = Object.keys(customer.social_media);
  // 	Object.values(customer.social_media).map((value, index) => {
  // 		if (value) {
  // 			formData.append(keys[index], value);
  // 		}
  // 	});
  // 	delete customer.social_media;
  // }

  const submitData = customer;
  const response = await api.post<Response<Customer>>(
    API_ROUTES.business.customers.create,
    serialize(submitData, undefined, formData),
  );
  const { data: data_1 } = response;
  if (!data_1.success) {
    console.log('not successful');
  }
  return response;
};

const getCustomerGeneralDetails = (uuid: string) =>
  api.get<Response<Customer>>(
    API_ROUTES.business.getCustomerGeneralDetails.concat(`${uuid}/`),
  );

const getCustomerCompanyDetails = async (
  uuid: string,
): Promise<Response<CompanyType>> =>
  api
    .get<Response<CompanyType>>(
      API_ROUTES.business.getCustomerCompanyDetails.concat(`${uuid}/`),
    )
    .then((response) => {
      const { data } = response;
      return data;
    });

const getCustomerSocialLinks = async (
  customerID: number,
): Promise<SocialLinks> =>
  api
    .get(API_ROUTES.business.getCustomerSocialLinks.concat(`${customerID}/`))
    .then((response) => {
      const { data } = response;
      return data;
    });

// update customer general information like first name, last_name, email and ...etc
const UpdatePersonalInfo = async (
  customerData: PersonalFormValues,
  uuid: string,
) => {
  const formData = new FormData();
  removeEmptyFields(customerData);
  const submitData = customerData;

  serialize(submitData, undefined, formData);

  // if (submitData.birthday) {
  //   formData.append('birthday', moment(submitData?.birthday).format('YYYY-MM-DD'));
  // }

  return await api
    .put<Response<Customer>>(
      API_ROUTES.business.updateCustomerGeneralDetails.concat(`${uuid}/`),
      submitData,
    )
    .then((response) => response);
};

// update customers company information where he work on it like company name, industry location and ...etc

// new request structure
const UpdateCompany = async (
  customerData: CompanyInfoFormValues,
  uuid: string,
) =>
  await api
    .put<Response<Customer>>(
      API_ROUTES.business.updateCustomerCompanyDetails.concat(`${uuid}/`),
      customerData,
    )
    .then((response) => response);

// update customer social media account address like instagram, twitter, telegram and ...etc
const UpdateSocialLinks = async (
  customerData: SocialLinksFormValues,
  uuid: string,
) =>
  await api
    .put<Response<SocialLinksFormValues>>(
      API_ROUTES.business.updateCustomerSocialLinks.concat(`${uuid}/`),
      customerData,
    )
    .then((response) => response);

// checking customer already is exist or its new customer
const CheckCustomerIsExist = async (data: {
  mobile?: string;
  email?: string;
}) =>
  await api
    .post<Response>(API_ROUTES.business.checkCustomer, data)
    .then((response) => response);

// upload customer avatar
const UploadCustomerAvatar = async (
  avatar: File,
  customerID: number,
): Promise<Response<Customer>> => {
  const formData = new FormData();
  formData.append('avatar', avatar);

  return await api
    .put<Response<Customer>>(
      API_ROUTES.business.uploadCustomerAvatar.concat(`${customerID}/`),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    .then(({ data }) => data);
};

// TODO: casing? try catch? react query needs the error
const DeleteCustomer = async (id: string | number) => {
  try {
    const response = await api.delete<Response>(
      API_ROUTES.business.customers.delete(id),
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

const updateCustomer = async ({
  data,
  id,
}: {
  data: CustomerFormSchema;
  id: string | number;
}) => {
  return api.patch<Response>(API_ROUTES.business.customers.edit(id), data);
};

const createCustomer = async (data: CustomerFormSchema) => {
  return api.post<Response>(API_ROUTES.business.customers.create, data);
};

const changePoints = async ({
  data,
  id,
}: {
  data: ChangePointsApiBody;
  id: string | number;
}) => {
  return api.post<Response>(
    API_ROUTES.business.customers.changePoints(id),
    data,
  );
};

const createCustomerWithFile = async ({ data }: { data: FormData }) => {
  return api.post<Response>(API_ROUTES.business.customers.csv_import, data);
};

const exportCustomerCSV = async (path: string) => {
  return api.post<Response>(path);
};

const createNote = async (data: { id: string; note: string }) => {
  return api.post<Response>(API_ROUTES.business.note.create(data?.id), {
    note: data?.note,
  });
};

const getNoteDetail = async (params: { id: number }) =>
  await api.get<Response>(`${API_ROUTES.business.note.detail}/${params.id}`);

const getCustomerNotes = (id: string, params: QueryParams) =>
  api.get<Response>(API_ROUTES.business.note.get(id, params), { params });

const deleteNote = (id: string | number) =>
  api.delete<Response>(API_ROUTES.business.note.delete(id));

const updateNote = (data: { id: string; note: string }) =>
  api.patch<Response>(API_ROUTES.business.note.update(data?.id), {
    note: data?.note,
  });

export default {
  getCustomerProfile,
  getCustomerGeneralDetails,
  getCustomerCompanyDetails,
  getCustomerSocialLinks,
  getCustomersList,
  UpdatePersonalInfo,
  UpdateCompany,
  UpdateSocialLinks,
  CheckCustomerIsExist,
  UploadCustomerAvatar,
  CreateNew,
  DeleteCustomer,
  getRFMChartData,
  cancelTokens: cancelTokenList,
  getCustomerPurchaseOverview,
  getCustomerClaimedRewadrs,
  updateCustomer,
  createCustomer,
  getCustomerTransactionsList,
  confirmTransaction,
  changePoints,
  getCustomerReferrals,
  getCustomerActivityTimeline,
  createCustomerWithFile,
  createNote,
  getCustomerNotes,
  deleteNote,
  updateNote,
  exportCustomerCSV,
  getNoteDetail,
};
