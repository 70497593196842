import Drawer from '../../../components/Drawer';
import { useEffect, useState } from 'react';
import NotificationContainer from './components/NotificationContainer';
import { Badge, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import noticeApi from 'api/notice';
import MenuIcons from 'Icons/MenuIcons';
import { useLocation } from 'react-router';

const Notifications: React.FC = () => {

  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const { data: notice } = useQuery(
    ['has_notice'],
    () => noticeApi.hasNotification(),
    {
      enabled: true,
      keepPreviousData: true,
      retry: false,
    },
  );

  // closes the drawer when page changes
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        style={{
          margin: '0 16px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div>
          <Badge
            color="error"
            variant="dot"
            invisible={!notice?.data?.data?.has_new_notice}
          >
            <IconButton color="default">
              <MenuIcons name="notif" />
            </IconButton>
          </Badge>
        </div>
      </div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <NotificationContainer toggleDrawer={toggleDrawer} />
      </Drawer>
    </>
  );
};

export default Notifications;
