import {
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from './Iconify';
import Page from './Page';
import i18next from 'i18next';

const RequestProgress = () => (
  <Page sx={{ height: '50vh' }} title="loading...">
    <Container maxWidth="lg" sx={{ height: '100%' }}>
      <Stack
        sx={{ height: '100%' }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Stack>
    </Container>
  </Page>
);

export default RequestProgress;

export const RequestProgressWithRetry = ({
  onClick,
  isLoading,
}: {
  isLoading: boolean;
  onClick: () => void;
}) => (
  <Stack
    sx={{ width: '100%', height: '100%' }}
    flexDirection="row"
    alignContent="center"
    justifyContent="center"
  >
    <Stack justifyContent="center">
      {isLoading ? (
        <RequestProgress />
      ) : (
        <Stack gap={2} alignItems={'center'}>
          <Typography>{i18next.t('common.something_wrong')}</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClick}
            startIcon={<Iconify icon="eva:sync-outline" />}
          >
            {i18next.t('common.try_again')}
          </Button>
        </Stack>
      )}
    </Stack>
  </Stack>
);
