import Loadable from 'components/Loadable';
import { lazy } from 'react';

const CustomerUpdate = Loadable(
  lazy(() => import('./UpdateCustomer/UpdateCustomer')),
);

const CustomersList = Loadable(
  lazy(() => import('./CustomersList/CustomersList')),
);
const CustomerCreate = Loadable(
  lazy(() => import('./CreateCustomer/CreateCustomer')),
);
const CustomerProfileDetails = Loadable(
  lazy(() => import('./CustomerDetails/CustomerDetails')),
);
const CustomerProfileOverview = Loadable(
  lazy(() => import('./CustomerDetails/Overview/CustomerOverview')),
);
const CustomerProfilePointsHistory = Loadable(
  lazy(() => import('./CustomerDetails/PointsHistory/PointsHistory')),
);
const CustomerProfilePurchaseHistory = Loadable(
  lazy(() => import('./CustomerDetails/PurchaseHistory/PurchaseHistory')),
);
const CustomerProfileClaimedRewards = Loadable(
  lazy(() => import('./CustomerDetails/ClaimedRewards/ClaimedRewards')),
);
const CustomerProfileReferrals = Loadable(
  lazy(() => import('./CustomerDetails/Referrals/Referrals')),
);
const CustomerProfileNotes = Loadable(
  lazy(() => import('./CustomerDetails/notes/CustomerNotes')),
);
const CustomerChangePointsDialog = Loadable(
  lazy(() => import('components/customers/ChangePointsDialog')),
);

export {
  CustomersList,
  CustomerCreate,
  CustomerUpdate,
  CustomerProfileDetails,
  CustomerProfileOverview,
  CustomerProfilePointsHistory,
  CustomerProfileClaimedRewards,
  CustomerProfilePurchaseHistory,
  CustomerProfileReferrals,
  CustomerProfileNotes,
  CustomerChangePointsDialog,
};
