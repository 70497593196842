import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

import Theme from './themes/philia';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { allLangs } from 'config';
import { ThemeLanguage } from 'components/settings/type';
import { useAtomSetting } from 'stateManagement/hooks/useSetting';
import initalI18n from 'locales/i18n';
import { useAtomValue } from 'jotai';
import { AtomSettingState } from 'stateManagement/atoms/settingAtom';

type Props = {
  children: ReactNode;
  selectedTheme?: typeof Theme;
};

const ThemeProvider = ({ children, selectedTheme }: Props) => {
  const useTheme = selectedTheme || Theme;
  const { typography } = selectedTheme || Theme;
  const { business_language } = useAtomValue(AtomSettingState);

  const ThemeOptions: ThemeOptions = useMemo(() => {
    const i18nextLng = localStorage.getItem('i18nextLng');
    initalI18n(i18nextLng as ThemeLanguage);
    return {
      palette: useTheme.light,
      typography: typography[business_language?.value],
      breakpoints,
      shape: { borderRadius: useTheme.shape.radius.global },
      radius: useTheme.shape.radius,
      direction: i18nextLng === 'en' ? 'ltr' : 'rtl',
      shadows: shadows.light,
      customShadows: customShadows.light,
      desingToken: useTheme.desingToken,
    };
  }, [business_language]);

  const theme = createTheme(ThemeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
