import { IconProps } from '../@types/icon';

const NotificationsIcon = ({
  color = '#000000',
  style,
  width = 22,
  height = 22,
}: IconProps) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66681 13.4812H2.91681H3.66681ZM5.8628 16.0562L5.75673 16.7986H5.75673L5.8628 16.0562ZM15.8186 16.0562L15.7126 15.3137L15.8186 16.0562ZM6.01085 7.41306C6.01085 4.74568 8.17319 2.58334 10.8406 2.58334V1.08334C7.34476 1.08334 4.51085 3.91726 4.51085 7.41306H6.01085ZM6.01085 8.95688V7.41306H4.51085V8.95688H6.01085ZM4.41681 13.4812C4.41681 13.0144 4.53993 12.5786 4.75494 12.2021L3.4524 11.4582C3.11141 12.0552 2.91681 12.7466 2.91681 13.4812H4.41681ZM5.96887 15.3137C5.07802 15.1864 4.41681 14.412 4.41681 13.4812H2.91681C2.91681 15.1273 4.09652 16.5614 5.75673 16.7986L5.96887 15.3137ZM10.8407 15.7501C9.37694 15.7501 7.45402 15.5259 5.96887 15.3137L5.75673 16.7986C7.25131 17.0121 9.26346 17.2501 10.8407 17.2501V15.7501ZM15.7126 15.3137C14.2274 15.5259 12.3045 15.7501 10.8407 15.7501V17.2501C12.418 17.2501 14.4301 17.0121 15.9247 16.7986L15.7126 15.3137ZM17.2646 13.4812C17.2646 14.412 16.6034 15.1864 15.7126 15.3137L15.9247 16.7986C17.5849 16.5614 18.7646 15.1273 18.7646 13.4812H17.2646ZM16.9265 12.202C17.1415 12.5785 17.2646 13.0143 17.2646 13.4812H18.7646C18.7646 12.7466 18.57 12.0551 18.229 11.458L16.9265 12.202ZM15.6703 7.41306V8.95646H17.1703V7.41306H15.6703ZM10.8406 2.58334C13.5079 2.58334 15.6703 4.74568 15.6703 7.41306H17.1703C17.1703 3.91725 14.3364 1.08334 10.8406 1.08334V2.58334ZM18.229 11.458C17.947 10.9644 17.691 10.564 17.4828 10.1239C17.2827 9.70116 17.1703 9.324 17.1703 8.95646H15.6703C15.6703 9.63269 15.8778 10.2391 16.127 10.7656C16.368 11.2748 16.6907 11.7893 16.9265 12.202L18.229 11.458ZM4.51085 8.95688C4.51085 9.32438 4.39849 9.70149 4.19844 10.1242C3.99021 10.5642 3.73429 10.9646 3.4524 11.4582L4.75494 12.2021C4.99064 11.7894 5.31331 11.275 5.55427 10.7659C5.80341 10.2394 6.01085 9.63304 6.01085 8.95688H4.51085Z"
      fill={color}
    />
    <path
      d="M12.9031 19.2767C12.4597 19.8293 11.7015 20.1934 10.8406 20.1934C9.97976 20.1934 9.22154 19.8293 8.77814 19.2767"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default NotificationsIcon;
