import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ControlLabel = (theme: Theme) => ({
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        ...theme.typography.body2,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginTop: theme.spacing(1),
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.disabled,
      },
    },
  },
});

export default ControlLabel;
