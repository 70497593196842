import { Color } from '@mui/material';

const GREEN: Color = {
  50: '',
  100: '#DCFCE7',
  200: '#BBF7D0',
  300: '#86EFAC',
  400: '#4ADE80',
  500: '#22C55E',
  600: '#16A34A',
  700: '#15803D',
  800: '#166534',
  900: '#14532D',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default GREEN;
