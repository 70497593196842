import { Color } from '@mui/material';

const WARNING: Color = {
  50: '',
  100: '#FFF3D6',
  200: '#FEEAB7',
  300: '#FEE08E',
  400: '#FCC951',
  500: '#FABB28',
  600: '#DE9828',
  700: '#C27023',
  800: '#995414',
  900: '#703704',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default WARNING;
