import { Notice, noticeContentTypes, noticeTypes } from '@/types/notice';
import CircleIcon from '@mui/icons-material/Circle';
import { Avatar, Grid, Typography, Button, Link } from '@mui/material';
import { DownloadFileIcon, NotificationsIcon } from '../../../../Icons';
import { Error, InfoOutlined } from '@mui/icons-material';
import AlertOutlinedIcon from 'Icons/AlertOutlined';
import FinanceCard from 'Icons/FinanceCard';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import NoticeIcon from 'Icons/NoticeIcon';
import { getTimeDifferenceFromNow } from 'utils/getTimeDifferenceFromNow';
import useLocales from '@/hooks/useLocales';
import { HOST_API } from 'config';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import customerApi from 'api/customer.api';
import { LoadingButton } from '@mui/lab';

const Notification: React.FC<Notice> = (props) => {
  const { translate } = useLocales();

  const navigate = useNavigate();

  const targetDate: Date = new Date(props.updated);

  const { days, hours, minutes, seconds } =
    getTimeDifferenceFromNow(targetDate);

  function formatTimeDifference(): string {
    if (days > 0) {
      return `${days} ${
        days > 1 ? translate('common.days') : translate('common.day')
      } ${translate('common.ago')}`;
    } else if (hours > 0) {
      return `${hours} ${
        hours > 1 ? translate('common.hours') : translate('common.hour')
      } ${translate('common.ago')}`;
    } else if (minutes > 0) {
      return `${minutes} ${
        minutes > 1 ? translate('common.minutes') : translate('common.minute')
      } ${translate('common.ago')}`;
    } else if (seconds > 0) {
      return `${seconds} ${
        seconds > 1 ? translate('common.seconds') : translate('common.second')
      } ${translate('common.ago')}`;
    } else {
      return translate('common.justNow');
    }
  }

  const noticeIcon = () => {
    switch (props.notice_type) {
      case noticeTypes.INFO:
        return <InfoOutlined />;
      case noticeTypes.WARNING:
        return <AlertOutlinedIcon color="#14181F" />;
      case noticeTypes.PAYMENT:
        return <FinanceCard />;
      case noticeTypes.ERROR:
        return <ReportGmailerrorredIcon />;
      case noticeTypes.OBJECT:
        return <NoticeIcon />;
      default:
        return <NotificationsIcon style={{ fontSize: '24px' }} />;
    }
  };

  const {
    isLoading: SeeNoteLoading,
    mutate: seeNote,
    error: seeNoteError,
  } = useMutation({
    mutationFn: () => customerApi.getNoteDetail({ id: props.object_id }),
    onSuccess: (data) => {
      const username = data?.data?.data?.customer?.username;
      if (username) {
        navigate(`/dashboard/customers/profile/${username}/notes`);
      }
    },
  });

  return (
    <Grid item xs={12}>
      <Grid container xs={12}>
        <Grid item width={'12%'}>
          <Avatar
            src={`${HOST_API}${props.sender?.avatar}`}
            sx={{ bgcolor: 'white', border: '#E6E7EA 1px solid' }}
          >
            {noticeIcon()}
          </Avatar>
        </Grid>
        <Grid item width={'82%'}>
          <Typography fontWeight={600} fontSize={'14px'}>
            {props.subject}
          </Typography>
          <Typography
            component={'p'}
            fontWeight={500}
            color={'InactiveCaptionText'}
            fontSize={'12px'}
            marginY={'4px'}
          >
            {props?.content}
          </Typography>
          <Typography
            component={'p'}
            fontWeight={500}
            color={'InactiveCaptionText'}
            fontSize={'12px'}
          >
            {formatTimeDifference()}
          </Typography>
          {props.attachment_file_path &&
            props.content_type?.name === noticeContentTypes.MEDIALIBRARY && (
              <Button
                component={Link}
                href={HOST_API.concat(props.attachment_file_path)}
                variant="secondary"
                startIcon={<DownloadFileIcon color="#5F646D" />}
                sx={{ marginTop: '10px' }}
              >
                {translate('common.notifications.downloadFile')}
              </Button>
            )}
          {props.content_type?.name === noticeContentTypes.NOTE && (
            <LoadingButton
              onClick={() => {
                seeNote();
              }}
              variant="secondary"
              endIcon={
                SeeNoteLoading ? undefined : seeNoteError ? (
                  <Error sx={{ color: '#5F646D' }} />
                ) : (
                  <ArrowOutwardIcon sx={{ color: '#5F646D' }} />
                )
              }
              sx={{ marginTop: '10px' }}
              disabled={!!SeeNoteLoading || !!seeNoteError}
              loading={SeeNoteLoading}
            >
              {seeNoteError
                ? translate('common.notifications.canNotFindThisNote')
                : translate('common.notifications.viewNote')}
            </LoadingButton>
          )}
        </Grid>
        <Grid item width={'6%'} justifyContent={'end'}>
          {!props.is_read && (
            <Grid container justifyContent={'end'}>
              <CircleIcon
                sx={{ fontSize: '8px', color: 'red', marginTop: '10px' }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Notification;
