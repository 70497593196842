import { IconProps } from '../@types/icon';

const CloseIcon = ({
  color = '#EB2A37',
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.75736 7.75732L16.2426 16.2426"
      stroke="#434856"
      stroke-width="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.75736 16.2426L16.2426 7.75732"
      stroke="#434856"
      stroke-width="1.5"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default CloseIcon;
