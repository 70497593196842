import { Color, darken } from '@mui/material';

const PHILIABRAND: Color = {
  50: '',
  100: darken('#7863F1', 0.1),
  200: darken('#7863F1', 0.15),
  300: darken('#7863F1', 0.18),
  400: darken('#7863F1', 0.23),
  500: '#7863F1',
  600: darken('#7863F1', 0.28),
  700: darken('#7863F1', 0.32),
  800: darken('#7863F1', 0.48),
  900: darken('#7863F1', 0.52),
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default PHILIABRAND;
