import { useCheckAccess } from 'hooks/useCheckAccess';
import useAuth from 'hooks/useAuth';
import { RoleApiResponse } from '@/types/role';
import useLocales from '@/hooks/useLocales';

const useFilteredRoutes = () => {
  const { user } = useAuth();
  const { translate } = useLocales();

  const checkAccess = (accessKeys: string[]) => {
    if (accessKeys.length === 0) return true;
    return accessKeys.some((key) =>
      useCheckAccess(key as keyof RoleApiResponse['permissions'], 'view'),
    );
  };

  interface RouteChild {
    id: string;
    path: string;
    name: string;
    disable: boolean;
    single: boolean;
    isChild?: boolean;
    accessKey: string;
    isSubRoute?: boolean;
  }

  interface MainRoute {
    id: number;
    name: string;
    title: string;
    path: string;
    iconName: string;
    keys: string[];
    children: RouteChild[];
    accessKey: string[];
  }

  type RoutesArray = MainRoute[];

  const mainRoutes: RoutesArray = [
    {
      id: 1,
      name: translate('common.sidebar.home'),
      title: translate('common.reports'),
      path: 'app',
      iconName: 'overview',
      keys: ['app'],
      accessKey: ['overview'],
      children: [
        {
          id: '1',
          path: 'app',
          name: translate('common.sidebar.home'),
          disable: false,
          single: false,
          accessKey: 'overview',
        },
      ],
    },
    {
      id: 2,
      name: translate('common.sidebar.customers'),
      title: 'CRM',
      path: 'customers',
      iconName: 'users',
      keys: ['customers', 'referrals'],
      accessKey: ['customers', 'referral'],
      children: [
        {
          id: '1',
          path: 'customers',
          name: translate('common.sidebar.customers'),
          disable: false,
          single: true,
          accessKey: 'customers',
        },
        {
          id: '2',
          path: 'customers/segments',
          name: translate('common.sidebar.segments'),
          disable: false,
          single: false,
          isChild: true,
          accessKey: 'customers',
        },
        {
          id: '3',
          path: 'referrals',
          name: translate('common.sidebar.referral_system'),
          disable: false,
          single: false,
          accessKey: 'referral',
        },
      ],
    },
    {
      id: 3,
      name: translate('common.sidebar.rewards'),
      title: translate('common.loyalty_program'),
      path: 'rewards',
      iconName: 'reward',
      keys: [
        'rewards',
        'gamification',
        'games',
        'badges',
        'coins',
        'tiers',
        'leaderboard',
        'tiers',
        'affiliate',
      ],
      accessKey: [
        'reward',
        'gamification',
        'tiersystem',
        'games',
        'leaderboard',
      ],
      children: [
        {
          id: '2',
          path: 'rewards',
          name: translate('common.sidebar.rewards'),
          disable: false,
          single: true,
          accessKey: 'reward',
        },
        {
          id: '3',
          path: 'tiers',
          name: translate('common.sidebar.tier_system'),
          disable: false,
          single: true,
          accessKey: 'tiersystem',
        },
        {
          id: '4',
          path: 'gamification/missions',
          name: translate('common.sidebar.missions'),
          disable: false,
          single: false,
          accessKey: 'gamification',
        },
        {
          id: '7',
          path: 'gamification/predefined-mission',
          name: translate('common.sidebar.predefined_missions'),
          disable: false,
          single: false,
          accessKey: 'gamification',
        },
        {
          id: '8',
          path: 'games',
          name: translate('common.sidebar.games'),
          disable: false,
          single: true,
          accessKey: 'games',
        },
        {
          id: '9',
          path: 'affiliate',
          name: translate('common.sidebar.affiliate'),
          disable: false,
          single: true,
          accessKey: 'affiliate',
        },
        {
          id: '10',
          path: 'gamification/leaderboard',
          name: translate('common.sidebar.leaderboard'),
          disable: false,
          single: true,
          accessKey: 'leaderboard',
        },
      ],
    },
    {
      id: 4,
      name: translate('common.sidebar.club'),
      title: translate('common.sidebar.club'),
      path: 'club/contents',
      iconName: 'club',
      keys: ['club', 'contents', 'style', 'illustrations'],
      accessKey: [],
      children: [
        {
          id: '1',
          path: 'club/contents',
          name: translate('common.sidebar.contents'),
          disable: false,
          single: false,
          accessKey: '',
        },
        {
          id: '2',
          path: 'club/style',
          name: translate('common.sidebar.style'),
          disable: false,
          single: false,
          accessKey: '',
        },
        {
          id: '3',
          path: 'club/illustrations',
          name: translate('common.sidebar.illustrations'),
          disable: false,
          single: false,
          accessKey: '',
        },
      ],
    },
    {
      id: 6,
      name: translate('common.sidebar.notifications'),
      title: translate('common.sidebar.marketing'),
      path: 'notifications',
      iconName: 'notifications',
      keys: ['notifications', 'tasks', 'journey'],
      accessKey: ['notification', 'tasks'],
      children: [
        {
          id: '1',
          path: 'notifications',
          name: translate('common.sidebar.notifications'),
          disable: false,
          single: true,
          accessKey: 'notification',
        },
        {
          id: '2',
          path: 'notifications/create',
          name: translate('common.sidebar.create_notif'),
          disable: false,
          single: false,
          accessKey: 'notification',
        },
        {
          id: '3',
          path: 'tasks',
          name: translate('common.sidebar.tasks'),
          disable: false,
          single: false,
          accessKey: 'tasks',
        },
        {
          id: '4',
          path: 'journey/list',
          name: translate('journey_page.journeys'),
          disable: false,
          single: false,
          accessKey: 'settings',
        },
      ],
    },
    {
      id: 7,
      name: translate('common.sidebar.panel_settings'),
      title: translate('common.setting'),
      path: 'user-management/general-setting',
      iconName: 'setting',
      keys: ['user-management', 'integrations', 'business-data'],
      accessKey: ['settings'],
      children: [
        {
          id: '1',
          path: 'user-management/general-setting',
          name: translate('common.sidebar.general_settings'),
          disable: false,
          single: false,
          accessKey: 'settings',
        },
        {
          id: '2',
          path: 'user-management/app-setting',
          name: translate('common.sidebar.app_settings'),
          disable: false,
          single: false,
          accessKey: 'settings',
        },
        {
          id: '3',
          path: 'user-management/business-profile',
          name: translate('common.sidebar.business_profile'),
          disable: false,
          single: false,
          accessKey: 'settings',
        },
        {
          id: '4',
          path: 'user-management/user-list',
          name: translate('common.sidebar.user_list'),
          disable: false,
          single: false,
          accessKey: 'settings',
        },
        {
          id: '5',
          path: 'user-management/roles',
          name: translate('common.sidebar.user_role'),
          disable: false,
          single: false,
          accessKey: 'settings',
        },
        {
          id: '6',
          path: 'user-management/user-log',
          name: translate('common.sidebar.user_logs'),
          disable: false,
          single: false,
          accessKey: 'settings',
        },
        {
          id: '7',
          path: 'integrations',
          name: translate('common.sidebar.integrations'),
          disable: false,
          single: true,
          accessKey: 'settings',
        },
        {
          id: '8',
          path: 'business-data/invoice',
          name: translate('common.sidebar.invoice_product'),
          disable: false,
          single: true,
          isSubRoute: true,
          accessKey: 'settings',
        },
      ],
    },
  ];

  const filterRoutes = (routes: MainRoute[]): MainRoute[] => {
    return routes.reduce((acc, route) => {
      if (checkAccess(route.accessKey)) {
        const filteredChildren =
          route.children?.filter((child) => checkAccess([child.accessKey])) ||
          [];
        acc.push({
          ...route,
          children: filteredChildren,
        });
      }
      return acc;
    }, [] as MainRoute[]);
  };

  const filteredMainRoutes: RoutesArray = filterRoutes(mainRoutes);

  return { list: user?.role === null ? mainRoutes : filteredMainRoutes };
};

export default useFilteredRoutes;
