import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

declare module '@mui/material' {
  interface TypographyPropsVariantOverrides {
    body1: true;
    body2: true;
    label: true;
  }
}
export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
