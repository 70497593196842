import { Color } from '@mui/material';

const ORANGE: Color = {
  50: '',
  100: '#FFE7DB',
  200: '#FDE68A',
  300: '#FCD34D',
  400: '#FBBF24',
  500: '#F59E0B',
  600: '#D97706',
  700: '#AC3E0B',
  800: '#92400E',
  900: '#78350F',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default ORANGE;
