import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Popover = (theme: Theme) => ({
  MuiPopover: {
    styleOverrides: {
      paper: {
        boxShadow: theme.customShadows.dropdown,
        borderRadius: Number(theme.shape.borderRadius) * 1.5,
      },
    },
  },
});

export default Popover;
