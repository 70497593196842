import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Menu = (theme: Theme) => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  },
});

export default Menu;
