import { Theme } from '@mui/material/styles';
import { GREY } from '../desing_system/colors';
import Token from '../themes/philia/token';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    ghost: true;
    destructiveFill: true;
    destructive: true;
    hightlight: true;
    confirm: true;
    warning: true;
    contained: true;
    text: true;
    outlined: true;
  }
}

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: Token.button_border_radius,
          fontSize: Token.button_font_size,
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          boxShadow:
            '0px 1px 2px -1px #0000000D , 0px 2px 4px -2px #0000000D, 0px 3px 6px -3px #0000000D',
          '&:disabled': {
            opacity: '50%',
          },
          '&.MuiButton-colorInherit': {
            borderColor: theme.palette.grey[300],
          },
        },
        sizeSmall: {
          padding: '5px 16px',
          height: '40px',
        },
        sizeMedium: {
          padding: '6px 12px',
        },
        sizeLarge: {
          padding: '15px 30px',
        },
        confirm: {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.success.dark,
          },
          '&:active': {
            backgroundColor: theme.palette.success.darker,
          },
          '&:disabled': {
            color: theme.palette.success.contrastText,
          },
        },
        warning: {
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.grey[700],
          '&:hover': {
            backgroundColor: theme.palette.warning.dark,
          },
          '&:active': {
            backgroundColor: theme.palette.warning.darker,
          },
          '&:disabled': {
            color: theme.palette.grey[700],
          },
        },
        ghost: {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: 'transparent !important',
            color: theme.palette.primary.dark,
          },
          '&:active': {
            color: theme.palette.primary.darker,
          },
          '&:disabled': {
            color: theme.palette.primary.main,
          },
        },
        text: {
          boxShadow: 'none !important',
        },
        primary: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&:active': {
            backgroundColor: theme.palette.primary.darker,
          },
          '&:focus': {
            backgroundColor: theme.palette.primary.darker,
          },
          '&:disabled': {
            color: theme.palette.primary.contrastText,
          },
        },
        secondary: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          border: '1px solid #D1D5DB',
          '&:hover': {
            backgroundColor: GREY[50],
          },
          '&:active': {
            backgroundColor: GREY[100],
          },
          '&:focus': {},
          '&:disabled': {
            color: theme.palette.secondary.contrastText,
          },
        },
        focusVisible: {},
        destructiveFill: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.error.dark,
          },
          '&:active': {
            backgroundColor: theme.palette.error.darker,
          },
          '&:disabled': {
            color: theme.palette.error.contrastText,
          },
        },
        destructive: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.error.main,
          border: '1px solid #D1D5DB',
          '&:hover': {
            backgroundColor: GREY[50],
          },
          '&:active': {
            backgroundColor: GREY[100],
          },
          '&:focus': {},
          '&:disabled': {
            color: theme.palette.error.main,
          },
        },
        hightlight: {
          backgroundColor: theme.palette.hightlight.main,
          color: theme.palette.hightlight.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.hightlight.dark,
          },
          '&:active': {
            backgroundColor: theme.palette.hightlight.darker,
          },
          '&:disabled': {
            color: theme.palette.hightlight.contrastText,
          },
        },
        containedInherit: {
          color: theme.palette.grey[800],
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {},
        containedSecondary: {},
        containedInfo: {},
        containedSuccess: {},
        containedWarning: {},
        containedError: {},
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
