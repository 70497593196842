import { Color } from '@mui/material';

const SUCCESS: Color = {
  50: '',
  100: '#E9FACD',
  200: '#D5F0AD',
  300: '#B0DB76',
  400: '#8FCC49',
  500: '#6BB319',
  600: '#528F15',
  700: '#40750E',
  800: '#2C5707',
  900: '#244D05',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default SUCCESS;
