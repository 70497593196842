import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Transition } from 'components/dialog/Transition';
import { Suspense, lazy } from 'react';
const CreateBulkPointsDialogContent = lazy(
  () =>
    import(
      '../../components/CreateBulkPointsDialog/CreateBulkPointsDialogContent'
    ),
);
import { useNavigate } from 'react-router';
import { LoadingCircular } from 'components/CircularLoading';
import useLocales from '@/hooks/useLocales';

const CreateBulkPointsModal = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      scroll="body"
      onClose={() => {
        navigate(-1);
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ p: 3 }}>
        {translate('task_page.bulk_accounts')}
      </DialogTitle>
      <Suspense
        fallback={
          <DialogContent>
            <LoadingCircular />
          </DialogContent>
        }
      >
        <CreateBulkPointsDialogContent />
      </Suspense>
    </Dialog>
  );
};

export default CreateBulkPointsModal;
