import { alpha, Theme } from '@mui/material/styles';
import { color, margin } from '@mui/system';

// ----------------------------------------------------------------------

const Pagination = (theme: Theme) => ({
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[500],
        '&.Mui-selected': {
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.primary.lighter,
          backgroundColor: 'transparent',
        },

        '&.MuiPaginationItem-previousNext': {
          border: `1px solid ${alpha(theme.palette.primary.main, 0.24)}`,
        },

        '&.MuiPaginationItem-page': {
          padding: '0px 2px',
          margin: '0px',
        },
      },
      textPrimary: {
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: `${alpha(
              theme.palette.primary.main,
              0.24,
            )} !important`,
          },
        },
      },
      outlined: {
        border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
      },
      outlinedPrimary: {
        '&.Mui-selected': {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
          border: `1px solid ${alpha(theme.palette.primary.main, 0.24)}`,
        },
      },
    },
  },
});

export default Pagination;
