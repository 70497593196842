// routes
import { router } from './routes';

// theme
import ThemeProvider from './theme';

// components
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import 'react-toastify/dist/ReactToastify.css';
import { RouterProvider } from 'react-router';
import ThemeRtlLayout from 'layouts/ThemeRtlLayout';

// ----------------------------------------------------------------------

const App = () => (
  <MotionLazyContainer>
    <ThemeProvider>
      <ThemeRtlLayout>
        <NotistackProvider>
          <ProgressBarStyle />
          <RouterProvider router={router} />
        </NotistackProvider>
      </ThemeRtlLayout>
    </ThemeProvider>
  </MotionLazyContainer>
);

export default App;
