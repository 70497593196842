import { array } from 'prop-types';

const removeEmptyFields = (data: object) => {
  if (data) {
    const a = Object(data);
    Object.keys(a).forEach((key) => {
      if (a[key] === undefined || a[key] === '' || a[key] === null) {
        delete a[key];
      }
    });
  }
};

export default removeEmptyFields;
