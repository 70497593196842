import { Color } from '@mui/material';

const INFO: Color = {
  50: '',
  100: '#E0F4FF',
  200: '#B0DFFB',
  300: '#7ACAF8',
  400: '#46B9FA',
  500: '#0098F0',
  600: '#0481CE',
  700: '#0B5489',
  800: '#0B5489',
  900: '#184266',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default INFO;
