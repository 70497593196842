import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Table = (theme: Theme) => ({
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderWidth: '1px 0px 1px 0px',
        borderStyle: 'solid',
        borderColor: '#EBEDEF',
        '&:last-of-type': {
          border: 'none',
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected,
          '&:hover': {
            // backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: 'none',
        padding: 8,
      },
      head: {
        borderWidth: '0px',
        borderColor: '#EBEDEF',
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[50],
        '&:first-of-type': {
          // paddingLeft: theme.spacing(3),
          // borderTopLeftRadius: theme.shape.borderRadius,
          // borderBottomLeftRadius: theme.shape.borderRadius,
          // boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
        },
        '&:last-of-type': {
          // paddingRight: theme.spacing(3),
          // borderTopRightRadius: theme.shape.borderRadius,
          // borderBottomRightRadius: theme.shape.borderRadius,
          // boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
        },
        '& > .MuiTableSortLabel-root:hover': {
          color: theme.palette.grey[700],
        },
      },
      stickyHeader: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
      },
      body: {
        '&:first-of-type': {
          // paddingLeft: theme.spacing(1),
        },
        '&:last-of-type': {
          // paddingRight: theme.spacing(3),
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        borderTop: `solid 1px ${theme.palette.divider}`,
      },
      toolbar: {
        height: 64,
      },
      select: {
        '&:focus': {
          borderRadius: theme.shape.borderRadius,
        },
      },
      selectIcon: {
        width: 20,
        height: 20,
        marginTop: -4,
      },
    },
  },
});

export default Table;
