import {
  Drawer as MaterialDrawer,
  DrawerProps as MaterialDrawerProps,
} from '@mui/material';
import React from 'react';

interface IDrawerProps extends MaterialDrawerProps {}

const Drawer: React.FC<IDrawerProps> = (props) => {
  return (
    <MaterialDrawer
      {...props}
    >
      {props.children}
    </MaterialDrawer>
  );
};

export default Drawer;
