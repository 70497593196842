import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Dialog = (theme: Theme) => ({
  MuiDialog: {
    styleOverrides: {
      paper: {
        // width: '100%',
        // maxWidth: '100%',
        overflow: 'visible',
        boxShadow: theme.customShadows.dialog,
        '&.MuiPaper-rounded': {
          borderRadius: Number(theme.shape.borderRadius) * 2,
        },
        '&.MuiDialog-paperFullScreen': {
          borderRadius: 0,
        },
        '&.MuiDialog-paper .MuiDialogActions-root': {
          padding: theme.spacing(3),
        },
        '@media (max-width: 600px)': {
          margin: theme.spacing(2),
        },
        '@media (max-width: 663.95px)': {
          '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
            // maxWidth: '100%',
          },
        },
      },
      paperFullWidth: {
        width: '100%',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        padding: theme.spacing(4),
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        borderTop: 0,
        borderBottom: 0,
        padding: theme.spacing(3),
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        // paddingLe: `${theme.spacing(2)} !important`,
        backgroundColor: theme.palette.grey[50],
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        '& > :not(:first-of-type)': {},
        padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
        borderRadius: '15px',
      },
    },
  },
});

export default Dialog;
