import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Notification from './Notification';
import React from 'react';
import { Notice } from '@/types/notice';

interface Props {
  notifications: Notice[] | undefined;
}

const NotificationGroup: React.FC<Props> = (props) => {
  return props.notifications && props.notifications?.length ? (
    <Grid item xs={12}>
      <Grid container xs={12} spacing={3}>
        {props?.notifications?.map((item: Notice) => (
          <Notification key={item.id} {...item} />
        ))}
      </Grid>
    </Grid>
  ) : (
    null
  );
};

export default NotificationGroup;
