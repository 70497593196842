import axios from '../utils/axios';
import API_ROUTES from '../api_endpints';
import { serialize } from 'object-to-formdata';
import { useSetAtom } from 'jotai';
import { AtomSettingState, Languages } from 'stateManagement/atoms/settingAtom';
import {
  BusinessSettings,
  Categories,
  Invoice,
  PanelSettingApiType,
  Product,
  PwaForm,
  PwaResponse,
} from '@/types/setting.type';
import { useQuery } from '@tanstack/react-query';
import { Pagination, QueryParams, Response } from '@/types/api';

const getDetails = async () => {
  const response = await axios.get<Response<BusinessSettings>>(
    API_ROUTES.settings.details,
  );
  return response;
};

const uploadImage = async (formData: FormData) => {
  return await axios.post(API_ROUTES.upload, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updateSettings = async (data: any, isFormData: boolean = true) => {
  try {
    if (isFormData) {
      const formData = new FormData();
      serialize(data, undefined, formData);
      const response = await axios.patch<
        Response<BusinessSettings['settings']>
      >(API_ROUTES.settings.update, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data.data;
    }

    const response = await axios.patch<Response<BusinessSettings['settings']>>(
      API_ROUTES.settings.update,
      data,
    );

    return response.data.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getSettings = async () => {
  return await axios.get<Response<PanelSettingApiType>>(
    API_ROUTES.accounts.config,
  );
};

export const useGetPanelSetting = () => {
  const setAtomSettingState = useSetAtom(AtomSettingState);
  return useQuery<PanelSettingApiType, Error>(
    ['PanelSettingQuery'],
    async () => {
      const response = await getSettings();
      return response.data.data;
    },
    {
      staleTime: 36000,
      select: (data) => {
        setAtomSettingState({
          ...data,
          language: Languages[data.language],
          business_language: Languages[data.business_language],
        });
        localStorage.setItem(
          'i18nextLng',
          Languages[data.business_language].value,
        );
        return data;
      },
    },
  );
};

const resetFactory = async (password: string) => {
  await axios.post(API_ROUTES.settings.resetFactory, { password });
};

const getInvoices = async (params: QueryParams) => {
  return await axios.get<Response<Pagination<Invoice>>>(
    API_ROUTES.invoiceAndProduct.invoice,
    { params },
  );
};

const createInvoiceWithFile = async ({ data }: { data: FormData }) => {
  return axios.post<Response>(
    API_ROUTES.invoiceAndProduct.invoice_csv_import,
    data,
  );
};

const createCategoryWithFile = async ({ data }: { data: FormData }) => {
  return axios.post<Response>(
    API_ROUTES.invoiceAndProduct.category_csv_import,
    data,
  );
};

const createProductWithFile = async ({ data }: { data: FormData }) => {
  return axios.post<Response>(
    API_ROUTES.invoiceAndProduct.product_csv_import,
    data,
  );
};

const postPwa = (data: PwaForm) => {
  const formData = new FormData();

  const dataArray = Object.entries(data);

  dataArray.forEach(([key, value]) => {
    formData.append(key, value);
  });

  return axios.post(API_ROUTES.themes.pwa, formData);
};

const getPwa = () => {
  return axios.get<Response<PwaResponse>>(API_ROUTES.themes.getPwa);
};

export default {
  updateSettings,
  resetFactory,
  getDetails,
  getInvoices,
  createInvoiceWithFile,
  createCategoryWithFile,
  createProductWithFile,
  uploadImage,
  postPwa,
  getPwa,
};
