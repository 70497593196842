// SettingsProvider.js or SettingsProvider.tsx
import { ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';

import businessApi from '../api/business.api';
import integrationApi from 'api/integration.api';
import { settingAtom } from 'stateManagement/atoms/settingAtom';
import { IntegrationConfiguration } from '@/types/business';

type SettingsProviderProps = {
  children: ReactNode;
};

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const [businessSetting, setSetting] = useAtom(settingAtom);

  const { settings } = businessSetting;

  const findFirstActiveIntegrationIndex = (
    integrationObject: IntegrationConfiguration,
  ) => {
    const values = Object.values(integrationObject);
    const index = values.findIndex((value) => value.is_active);
    return index;
  };

  const { data: businessData } = useQuery(
    ['get-business'],
    () => businessApi.getSettings(),
    {
      enabled: window.location.pathname.includes('/dashboard'),
    },
  );

  const { data: integrationData } = useQuery(
    ['get-integration'],
    () => integrationApi.getAllIntegrations(),
    {
      enabled: window.location.pathname.includes('/dashboard'),
    },
  );

  useEffect(() => {
    if (!window.location.pathname.includes('auth')) {
      if (businessData?.status === 200 && integrationData?.status === 200) {
        const settingResponse = businessData?.data?.data;
        const integrationResponse = integrationData?.data?.data;

        const activeIntegrationIndex = findFirstActiveIntegrationIndex(
          integrationResponse?.integrationconfiguration,
        );

        setSetting((prevSetting) => ({
          ...prevSetting,
          coins: settingResponse.coins,
          settings: {
            ...settingResponse.settings,
            integrationData: integrationResponse,
            integration: activeIntegrationIndex || null,
          },
        }));
      }
    }
  }, [
    settings.is_first_fender_after_login,
    setSetting,
    integrationData,
    businessData,
  ]);

  return <>{children}</>;
};

export { SettingsProvider };
