import { RoleApiResponse } from '../@types/role';
import useAuth from 'hooks/useAuth';

export const useCheckAccess = (
  page: keyof RoleApiResponse['permissions'],
  role: string,
) => {
  const { user } = useAuth();

  if (user?.role === null) return true;

  if (typeof user?.role !== 'string' && !user?.role?.permissions[page])
    return false;

  return (
    typeof user?.role !== 'string' &&
    user?.role?.permissions[page]?.includes(role)
  );
};
