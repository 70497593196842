import { Grid, Skeleton } from '@mui/material';

const NotificationLoading = () => {
  return (
    <Grid item xs={12}>
      <Grid container xs={12} spacing={3}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2}>
            <Skeleton variant="circular" height={40} width={40}></Skeleton>
          </Grid>
          <Grid item xs={10}>
            <Skeleton width="80%" />
            <Skeleton width="60%" />
            <Skeleton width="40%" />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2}>
            <Skeleton variant="circular" height={40} width={40}></Skeleton>
          </Grid>
          <Grid item xs={10}>
            <Skeleton width="80%" />
            <Skeleton width="60%" />
            <Skeleton width="40%" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotificationLoading;
