import { Theme } from '@mui/material/styles';
//
import {
  CheckboxIcon,
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
  CheckboxNullIcon,
} from './CustomIcons';

// ----------------------------------------------------------------------

const Checkbox = (theme: Theme) => ({
  MuiCheckbox: {
    defaultProps: {
      icon: (
        <CheckboxNullIcon
          sx={{
            mr: 1,
          }}
        />
      ),
      checkedIcon: (
        <CheckboxCheckedIcon
          color="primary"
          sx={{
            mr: 1,
          }}
        />
      ),
      indeterminateIcon: <CheckboxIndeterminateIcon color="primary" />,
      disableRipple: true,
    },

    styleOverrides: {
      root: {
        '&.Mui-disabled > .MuiSvgIcon-root': {
          color: `${theme.palette.grey[400]} !important`,
        },
        padding: 0,
        '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
          color: theme.palette.action.disabled,
        },

        '& .MuiSvgIcon-fontSizeMedium': {
          width: 24,
          height: 24,
        },
        '& .MuiSvgIcon-fontSizeSmall': {
          width: 20,
          height: 20,
        },
        svg: {
          fontSize: 24,
          '&[font-size=small]': {
            fontSize: 20,
          },
        },
      },
    },
  },
});

export default Checkbox;
