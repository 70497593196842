export const getTimeDifferenceFromNow: (targetDate: Date) => {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} = (targetDate) => {
  // Get the current date and time
  const currentDate: Date = new Date();
  // Calculate the difference in milliseconds
  const difference: number = currentDate.getTime() - targetDate.getTime();

  // Convert the difference to seconds, minutes, hours, and days
  const seconds: number = Math.floor(difference / 1000);
  const minutes: number = Math.floor(seconds / 60);
  const hours: number = Math.floor(minutes / 60);
  const days: number = Math.floor(hours / 24);

  return { days, hours, minutes, seconds };
};
