import API_ROUTES from '../api_endpints';
import axios from '../utils/axios';
import { Response } from '../@types/api';

const getNotices = async (params: {is_read? : boolean , page_size : number}) =>
  await axios.get<Response>(API_ROUTES.notice.noticeList, {
    params,
  });

const hasNotification = async () =>
  await axios.get<Response>(API_ROUTES.notice.hasNotice);

const markAllAsRead = async () =>
  await axios.put<Response>(API_ROUTES.notice.markAllAsRead);

export default {
  getNotices,
  markAllAsRead,
  hasNotification,
};
