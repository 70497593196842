import { alpha } from '@mui/material/styles';
import { Color } from '@mui/material';
import desingToken from './token';
import typography from './typography';
import {
  VIOLET,
  GREEN,
  INFO,
  SUCCESS,
  ERROR,
  WARNING,
  GREY,
  BLUE,
  RED,
  YELLOW,
  PHILIABRAND,
  WHITE,
  ORANGE,
} from '../../desing_system/colors';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

function createColorObject(COLOR: Color) {
  return {
    lighter: COLOR[100],
    light: COLOR[300],
    main: COLOR[500],
    dark: COLOR[600],
    darker: COLOR[700],
  };
}

// SETUP COLORS

const PRIMARY = createColorObject(PHILIABRAND);
const SECONDARY = createColorObject(WHITE);
const INFO2 = createColorObject(INFO);
const SUCCESS2 = createColorObject(SUCCESS);
const WARNING2 = createColorObject(WARNING);
const ERROR2 = createColorObject(ERROR);
const HIGHTLIGHT = createColorObject(ORANGE);

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO2.light, INFO2.main),
  success: createGradient(SUCCESS2.light, SUCCESS2.main),
  warning: createGradient(WARNING2.light, WARNING2.main),
  error: createGradient(ERROR2.light, ERROR2.main),
};

const CHART_COLORS = {
  violet: [VIOLET[500], VIOLET[400], VIOLET[300], VIOLET[200]],
  blue: [BLUE[500], BLUE[400], BLUE[300], BLUE[200]],
  green: [GREEN[500], GREEN[400], GREEN[300], GREEN[200]],
  yellow: [YELLOW[500], YELLOW[400], YELLOW[300], YELLOW[200]],
  red: [RED[500], RED[400], RED[300], RED[200]],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#000' },
  info: { ...INFO2, contrastText: '#fff' },
  success: { ...SUCCESS2, contrastText: '#fff' },
  warning: { ...WARNING, contrastText: '#fff' },
  error: { ...ERROR2, contrastText: '#fff' },
  hightlight: { ...HIGHTLIGHT, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: alpha(GREY[500], 0.24),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    // disabled: alpha(GREY[500], 0.8),
    // disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

// 500_8: alpha('#919EAB', 0.08),
// 500_12: alpha('#919EAB', 0.12),
// 500_16: alpha('#919EAB', 0.16),
// 500_24: alpha('#919EAB', 0.24),
// 500_32: alpha('#919EAB', 0.32),
// 500_48: alpha('#919EAB', 0.48),
// 500_56: alpha('#919EAB', 0.56),
// 500_80: alpha('#919EAB', 0.8),

const palette = {
  typography,
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: '#182129', disabled: GREY[500] },
    background: { paper: '#fff', default: '#F4F5F5', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
    divider: alpha(GREY[500], 0.24),
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: { active: GREY[500], ...COMMON.action },
    divider: alpha(GREY[500], 0.24),
  },
  desingToken,
  shape: {
    radius: {
      global: desingToken.button_border_radius,
    },
  },
} as const;

export default palette;
