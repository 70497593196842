// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, BoxProps } from '@mui/material';
//
import Image from './Image';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  title: string;
  description?: string;
}

const ForbiddenComponent = ({ title, description, ...other }: Props) => (
  <RootStyle {...other}>
    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>

    {description && (
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {description}
      </Typography>
    )}
  </RootStyle>
);

export default ForbiddenComponent;
