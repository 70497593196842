import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Breadcrumbs = (theme: Theme) => ({
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
  },
});

export default Breadcrumbs;
