import actions from './pages/actions.json';
import auth from './pages/auth.json';
import customers from './pages/customers.json';
import segments from './pages/segments.json';
import flows from './pages/flows.json';
import games from './pages/games.json';
import missions from './pages/missions.json';
import gamification from './pages/gamification.json';
import notifications from './pages/notifications.json';
import overview from './pages/overview.json';
import predefinedMissions from './pages/predefined-missions.json';
import rewards from './pages/rewards.json';
import tasks from './pages/tasks.json';
import tierSystem from './pages/tier-system.json';
import commons from './pages/commons.json';
import panelSetting from './pages/panel-settings.json';
import profile from './pages/profile.json';
import integrations from './pages/integrations.json';
import referral from './pages/referrals.json';
import networkErr from './network-errors.json';
import formErr from './form-errors.json';
import googleFormAction from './pages/google-form-action.json';
import journeys from './pages/journeys.json';
import leaderboard from './pages/leaderboard.json';
import club from './pages/club.json';

export default {
  ...actions,
  ...auth,
  ...customers,
  ...flows,
  ...games,
  ...missions,
  ...notifications,
  ...overview,
  ...predefinedMissions,
  ...rewards,
  ...tasks,
  ...tierSystem,
  ...commons,
  ...profile,
  ...integrations,
  ...gamification,
  ...panelSetting,
  ...networkErr,
  ...formErr,
  ...segments,
  ...referral,
  ...googleFormAction,
  ...journeys,
  ...leaderboard,
  ...club,
};
