import { IconProps } from '../@types/icon';

const NoticeIcon = ({
  color = '#14181F',
  width = 18,
  height = 18,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.76257 0.843635C8.44598 0.160201 9.55403 0.160194 10.2375 0.843618L17.1566 7.76276C17.84 8.44617 17.84 9.55422 17.1566 10.2376L10.2375 17.1567C9.55403 17.8401 8.44599 17.8401 7.76257 17.1567L0.843609 10.2376C0.160208 9.55423 0.160202 8.44621 0.8436 7.76279L7.76257 0.843635ZM9.1768 1.90428C9.07917 1.80665 8.92087 1.80665 8.82324 1.90428L5.92623 4.80137L9.06437 7.93952L12.1382 4.86569L9.1768 1.90428ZM8.00371 9.00018L4.86558 5.86205L1.90428 8.82344C1.80665 8.92107 1.80665 9.07936 1.90428 9.17699L4.86557 12.1383L8.00371 9.00018ZM9.06437 10.0608L12.1382 13.1347L9.17679 16.0961C9.07916 16.1937 8.92087 16.1937 8.82324 16.0961L5.92622 13.199L9.06437 10.0608ZM13.1989 12.074L10.125 9.00018L13.1989 5.92635L16.0959 8.82341C16.1936 8.92105 16.1936 9.07934 16.0959 9.17697L13.1989 12.074Z"
      fill={color}
    />
  </svg>
);

export default NoticeIcon;
