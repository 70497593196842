import { lazy } from 'react';
import { Navigate, createBrowserRouter, useRouteError } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// config
import { PATH_AFTER_LOGIN } from '../config';

// components
import AccessGuard from 'guards/AccessGuard';
import Loadable from 'components/Loadable';

import RequestProgressFailed from 'components/RequestProgressFailed';

// ------------------------ customers
import {
  CustomerCreate,
  CustomersList,
  CustomerUpdate,
  CustomerProfileDetails,
  CustomerProfileOverview,
  CustomerProfilePointsHistory,
  CustomerProfilePurchaseHistory,
  CustomerProfileClaimedRewards,
  CustomerProfileReferrals,
  CustomerChangePointsDialog,
  CustomerProfileNotes,
} from 'pages/dashboard/Customers';

// ------------------------ segments
import {
  CreateSegment,
  DetailSegment,
  UpdateSegment,
} from 'pages/dashboard/Segments';

// ------------------------ predefinemissions
import { PreMissionList, PreMissionUpdate } from 'pages/dashboard/PreMissions';

// ------------------------ missions
import { CreateMission, UpdateMission } from 'pages/dashboard/Missions';

// TODO: Create a components.route.ts. Create a json for these paths in it and then loop through them to create these lazy components.
// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login/Login')));
const Register = Loadable(
  lazy(() => import('../pages/auth/Register/Register')),
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword/ResetPassword')),
);
const NewPassword = Loadable(
  lazy(() => import('../pages/auth/NewPassword/NewPassword')),
);
const VerifyCode = Loadable(
  lazy(() => import('../pages/auth/VerifyCode/VerifyCode')),
);

const UserProfile = Loadable(
  lazy(() => import('../pages/dashboard/Profile/Profile')),
);

// ------------------------ leaderboards
import { LeaderboardList } from 'pages/dashboard/Leaderboard';

// ------------------------ notifications
import {
  NotificationsList,
  CreateNotification,
  UpdateNotification,
} from 'pages/dashboard/Notifications';

const IntegrationsApp = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/Integrations')),
);

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import('../pages/dashboard/GeneralApp')),
);

const CampaignsApp = Loadable(
  lazy(() => import('../pages/dashboard/Campaigns')),
);

// REWARDS
// const SelectRewardType = Loadable(lazy(() => import('../pages/dashboard/Rewards/SelectType')));
const CreateReward = Loadable(
  lazy(() => import('../pages/dashboard/Rewards/CreateReward/CreateReward')),
);
const UpdateReward = Loadable(
  lazy(() => import('../pages/dashboard/Rewards/UpdateReward/UpdateReward')),
);
const Rewards = Loadable(
  lazy(() => import('../pages/dashboard/Rewards/RewardsList/RewardsList')),
);

const UserManagementApp = Loadable(
  lazy(() => import('../pages/dashboard/PanelSettings/PanelSettings')),
);

const AppSettings = Loadable(
  lazy(() => import('../pages/dashboard/appSettings')),
);

const CreateUser = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/PanelSettings/UsersList/CreateUser/CreateUser'
      ),
  ),
);
const EditUser = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/PanelSettings/UsersList/EditUser/EditUser'),
  ),
);

const CreateRole = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/PanelSettings/UsersRoles/CreateRole/CreateRole'
      ),
  ),
);
const EditRole = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/PanelSettings/UsersRoles/UpdateRole/UpdateRote'
      ),
  ),
);

// coins
const CoinsApp = Loadable(
  lazy(() => import('../pages/dashboard/PointSystem/Coins')),
);

const Affiliate = Loadable(lazy(() => import('../pages/dashboard/Affiliate')));
const AffiliateReport = Loadable(
  lazy(() => import('../pages/dashboard/Affiliate/Report')),
);
const AffiliateSettings = Loadable(
  lazy(() => import('../pages/dashboard/Affiliate/SettingsPage')),
);

// coins
const GamificationApp = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Gamification/GamificationsList/GamificationsList'
      ),
  ),
);
const GamificationCreate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Gamification/CreateGamification/CreateGamification'
      ),
  ),
);
const GamificationUpdate = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Gamification/UpdateGamification/UpdateGamification'
      ),
  ),
);
const Tasks = Loadable(lazy(() => import('../pages/dashboard/Tasks/Tasks')));

const BulkPointsList = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/Tasks/BulkPoints/BulkPointsList/BulkPointsList'
      ),
  ),
);

// For later we can add sometype of loading for modal pages. This page is pretty light at it's first load though.
import CreateBulkPoints from '../pages/dashboard/Tasks/BulkPoints/CreateBulkPoints/CreateBulkPoints';
import {
  CreateReferral,
  ReferralsList,
  UpdateReferral,
} from 'pages/dashboard/Referrals';

// invoice and products
const InvoiceAndProduct = Loadable(
  lazy(() => import('../pages/dashboard/InvoiceAndProduct')),
);

// tiers
const TiersApp = Loadable(lazy(() => import('../pages/dashboard/TierSystem')));

// USER
const UserAccount = Loadable(
  lazy(() => import('../pages/dashboard/UserAccount')),
);

// journey
const JourneyList = Loadable(
  lazy(() => import('../pages/dashboard/Journeys/list')),
);

const CreateJourney = Loadable(
  lazy(() => import('../pages/dashboard/Journeys/create')),
);

const EditJourney = Loadable(
  lazy(() => import('../pages/dashboard/Journeys/edit')),
);

const JourneySettingsEdit = Loadable(
  lazy(() => import('../pages/dashboard/Journeys/edit/editSettings')),
);

const JourneyFlowsEdit = Loadable(
  lazy(() => import('../pages/dashboard/Journeys/edit/EditFlows')),
);

const JourneyStatistics = Loadable(
  lazy(() => import('../pages/dashboard/Journeys/statistics')),
);

const ClubContents = Loadable(
  lazy(() => import('../pages/dashboard/club/clubContents/Layout')),
);

const ClubStyle = Loadable(lazy(() => import('../pages/dashboard/club/style')));

const ClubIllustrations = Loadable(
  lazy(() => import('../pages/dashboard/club/Illustrations')),
);

const ClubLayout = Loadable(lazy(() => import('../pages/dashboard/club')));

const ErrorBoundary = () => {
  const error: any = useRouteError();

  if (process.env.NODE_ENV === 'development') {
    console.log('err =>', error);
  }

  return (
    <DashboardLayout>
      <>
        {process.env.NODE_ENV === 'development' && (
          <div>Error: {error.message}</div>
        )}
        <RequestProgressFailed />
      </>
    </DashboardLayout>
  );
};

export const routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      { path: 'login-unprotected', element: <Login /> },
      { path: 'register-unprotected', element: <Register /> },
      {
        path: 'reset-password',
        element: (
          <GuestGuard>
            <ResetPassword />
          </GuestGuard>
        ),
      },
      {
        path: 'new-password/:id/:token',
        element: (
          <GuestGuard>
            <NewPassword />
          </GuestGuard>
        ),
      },
      { path: 'verify', element: <VerifyCode /> },
    ],
  },

  // Dashboard Routes
  {
    path: '/dashboard',
    errorElement: <ErrorBoundary />,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      {
        path: 'app',
        element: (
          <AccessGuard page="overview" role="view">
            <GeneralApp />
          </AccessGuard>
        ),
      },
      {
        path: 'customers',
        children: [
          {
            element: (
              <AccessGuard page="customers" role="view">
                <CustomersList />
              </AccessGuard>
            ),
            index: true,
          },
          {
            path: 'segments',
            element: (
              <AccessGuard page="customers" role="change">
                <CustomersList />
              </AccessGuard>
            ),
          },
          {
            path: 'segments/create',
            element: (
              <AccessGuard page="customers" role="change">
                <CreateSegment />
              </AccessGuard>
            ),
          },
          {
            path: 'segments/detail/:id',
            element: (
              <AccessGuard page="customers" role="change">
                <DetailSegment />
              </AccessGuard>
            ),
          },
          {
            path: 'segments/edit/:id',
            element: (
              <AccessGuard page="customers" role="change">
                <UpdateSegment />
              </AccessGuard>
            ),
          },
          {
            path: 'create',
            element: (
              <AccessGuard page="customers" role="add">
                <CustomerCreate />
              </AccessGuard>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <AccessGuard page="customers" role="change">
                <CustomerUpdate />
              </AccessGuard>
            ),
          },
          {
            path: 'profile/:id',
            element: (
              <AccessGuard page="customers" role="view">
                <CustomerProfileDetails />
              </AccessGuard>
            ),

            children: [
              {
                path: 'overview',
                element: <CustomerProfileOverview />,
                children: [
                  {
                    path: 'points/add-delete',
                    element: <CustomerChangePointsDialog />,
                  },
                ],
              },
              {
                path: 'points-history',
                element: <CustomerProfilePointsHistory />,
              },
              {
                element: <CustomerProfilePurchaseHistory />,
                path: 'purchase-history',
              },
              {
                element: <CustomerProfileClaimedRewards />,
                path: 'claimed-rewards',
              },
              {
                element: <CustomerProfileReferrals />,
                path: 'referrals',
              },
              {
                element: <CustomerProfileNotes />,
                path: 'notes',
              },
            ],
          },
        ],
      },
      {
        path: 'rewards',
        children: [
          {
            element: (
              <AccessGuard page="reward" role="view">
                <Rewards />
              </AccessGuard>
            ),
            index: true,
          },
          {
            path: 'create',
            element: (
              <AccessGuard page="reward" role="add">
                <CreateReward />
              </AccessGuard>
            ),
          },
          {
            path: 'create/:id/:type',
            element: (
              <AccessGuard page="reward" role="add">
                <CreateReward />
              </AccessGuard>
            ),
          },
          {
            path: 'edit/:type/:id',
            element: (
              <AccessGuard page="reward" role="change">
                <UpdateReward />
              </AccessGuard>
            ),
          },
          {
            path: 'edit/:type/:id/code',
            element: (
              <AccessGuard page="reward" role="change">
                <UpdateReward />
              </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'referrals',
        children: [
          {
            element: (
              <AccessGuard page="referral" role="view">
                <ReferralsList />
              </AccessGuard>
            ),
            index: true,
          },
          {
            path: 'create',
            element: (
              <AccessGuard page="referral" role="add">
                <CreateReferral />
              </AccessGuard>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <AccessGuard page="referral" role="change">
                <UpdateReferral />
              </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'tiers',
        children: [
          {
            element: (
              <AccessGuard page="tiersystem" role="view">
                <TiersApp />
              </AccessGuard>
            ),
            index: true,
          },
          {
            path: 'breakdown_guides',
            element: (
              <AccessGuard page="tiersystem" role="view">
                <TiersApp />
              </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'gamification',
        children: [
          {
            path: 'missions',
            children: [
              {
                element: (
                  <AccessGuard page="gamification" role="view">
                    <CampaignsApp />
                  </AccessGuard>
                ),
                index: true,
              },
              {
                path: 'create',
                element: (
                  <AccessGuard page="gamification" role="add">
                    <CreateMission />
                  </AccessGuard>
                ),
              },
              {
                path: 'edit/:id',
                element: (
                  <AccessGuard page="gamification" role="change">
                    <UpdateMission />
                  </AccessGuard>
                ),
              },
            ],
          },
          {
            path: 'predefined-mission',
            children: [
              {
                element: (
                  <AccessGuard page="gamification" role="view">
                    <PreMissionList />
                  </AccessGuard>
                ),
                index: true,
              },
              {
                path: 'edit/:id',
                element: (
                  <AccessGuard page="gamification" role="change">
                    <PreMissionUpdate />
                  </AccessGuard>
                ),
              },
            ],
          },
          {
            path: 'leaderboard',
            children: [
              {
                element: (
                  <AccessGuard page="leaderboard" role="view">
                    <LeaderboardList />
                  </AccessGuard>
                ),
                index: true,
              },
              // {
              //   path: 'create',
              //   element: (
              //     <AccessGuard page="notification" role="add">
              //       <CreateNotification />
              //     </AccessGuard>
              //   ),
              // },
            ],
          },
        ],
      },

      {
        path: 'coins',
        children: [
          {
            element: (
              <AccessGuard page="settings" role="view">
                <CoinsApp />
              </AccessGuard>
            ),
            index: true,
          },
        ],
      },
      {
        path: 'games',
        children: [
          {
            element: (
              <AccessGuard page="games" role="view">
                <GamificationApp />
              </AccessGuard>
            ),
            index: true,
          },
          {
            path: 'create',
            element: (
              <AccessGuard page="games" role="add">
                <GamificationCreate />
              </AccessGuard>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <AccessGuard page="games" role="change">
                <GamificationUpdate />
              </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'affiliate',
        children: [
          {
            element: (
              <AccessGuard page="affiliate" role="view">
                <Affiliate />
              </AccessGuard>
            ),
            index: true,
          },
          {
            path: 'report/:id',
            element: (
              <AccessGuard page="affiliate" role="view">
                <AffiliateReport />
              </AccessGuard>
            ),
          },
          {
            path: 'settings',
            element: (
              <AccessGuard page="affiliate" role="view">
                <AffiliateSettings />
              </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'tasks',
        children: [
          {
            index: true,
            element: (
              <AccessGuard page="task" role="view">
                <Tasks />
              </AccessGuard>
            ),
          },
          {
            path: 'bulk-points',
            element: (
              <AccessGuard page="task" role="view">
                <BulkPointsList />
              </AccessGuard>
            ),
            children: [
              {
                path: 'create',
                element: (
                  <AccessGuard page="task" role="add">
                    <CreateBulkPoints />
                  </AccessGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'user-management',
        children: [
          {
            index: true,
            path: 'general-setting',
            element: (
              <AccessGuard page="settings" role="view">
                <UserManagementApp />
              </AccessGuard>
            ),
          },
          {
            path: 'app-setting',
            element: (
              <AccessGuard page="settings" role="view">
                <AppSettings />
              </AccessGuard>
            ),
          },
          {
            path: 'business-profile',
            element: (
              <AccessGuard page="settings" role="view">
                <UserManagementApp />
              </AccessGuard>
            ),
          },
          {
            path: 'customer-branding',
            element: (
              <AccessGuard page="settings" role="view">
                <UserManagementApp />{' '}
              </AccessGuard>
            ),
          },
          {
            path: 'user-list',
            element: (
              <AccessGuard page="settings" role="view">
                <UserManagementApp />
              </AccessGuard>
            ),
          },
          {
            path: 'user-log',
            element: (
              <AccessGuard page="settings" role="view">
                <UserManagementApp />
              </AccessGuard>
            ),
          },
          {
            path: 'roles',
            element: (
              <AccessGuard page="settings" role="view">
                <UserManagementApp />
              </AccessGuard>
            ),
          },
          {
            path: 'roles/:id',
            element: (
              <AccessGuard page="settings" role="change">
                <EditRole />
              </AccessGuard>
            ),
          },
          {
            path: 'roles/new-role',
            element: (
              <AccessGuard page="settings" role="add">
                <CreateRole />
              </AccessGuard>
            ),
          },
          {
            path: 'user-list/new-user',
            element: (
              <AccessGuard page="settings" role="add">
                <CreateUser />
              </AccessGuard>
            ),
          },
          {
            path: 'user-list/edit/:id',
            element: (
              <AccessGuard page="settings" role="change">
                <EditUser />
              </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'profile',
        element: <UserProfile />,
      },

      {
        path: 'notifications',
        children: [
          {
            element: (
              <AccessGuard page="notification" role="view">
                <NotificationsList />
              </AccessGuard>
            ),
            index: true,
          },
          {
            path: 'create',
            element: (
              <AccessGuard page="notification" role="add">
                <CreateNotification />
              </AccessGuard>
            ),
          },
          {
            path: 'edit',
            children: [
              {
                path: ':type/:id/',
                element: (
                  <AccessGuard page="notification" role="change">
                    <UpdateNotification />
                  </AccessGuard>
                ),
              },
              {
                path: ':type/:id/statistics',
                element: (
                  <AccessGuard page="notification" role="change">
                    <UpdateNotification />
                  </AccessGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'integrations',
        element: (
          <AccessGuard page="settings" role="view">
            <IntegrationsApp />
          </AccessGuard>
        ),
      },
      {
        path: 'integrations/:name/:user_id',
        element: (
          <AccessGuard page="settings" role="view">
            <IntegrationsApp />
          </AccessGuard>
        ),
      },
      {
        path: 'business-data',
        children: [
          {
            path: 'invoice',
            element: (
              // <AccessGuard page="notification" role="change">
              <InvoiceAndProduct />
              // </AccessGuard>
            ),
          },
          {
            path: 'product',
            element: (
              // <AccessGuard page="notification" role="change">
              <InvoiceAndProduct />
              // </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'user',
        children: [
          {
            element: <Navigate to="/dashboard/user/profile" replace />,
            index: true,
          },
          {
            path: 'profile',
            element: (
              <AccessGuard page="settings" role="view">
                <UserAccount />
              </AccessGuard>
            ),
          },
        ],
      },
      {
        path: 'journey',
        children: [
          {
            path: 'list',
            element: <JourneyList />,
          },
          {
            path: 'create',
            element: <CreateJourney />,
          },
          {
            path: 'edit/:id',
            element: <EditJourney />,
            children: [
              {
                path: 'settings',
                element: <JourneySettingsEdit />,
              },
              {
                path: 'flows',
                element: <JourneyFlowsEdit />,
              },
              {
                path: 'statistics',
                element: <JourneyStatistics />,
              },
            ],
          },
        ],
      },
      {
        path: 'club',
        element: <ClubLayout />,
        children: [
          {
            path: 'contents',
            element: <ClubContents />,
          },
          {
            path: 'style',
            element: <ClubStyle />,
          },
          {
            path: 'illustrations',
            element: <ClubIllustrations />,
          },
        ],
      },
    ],
  },
  { path: '*', element: <Navigate to="/dashboard" replace /> },
];

export const router = createBrowserRouter(routes);
