import { Theme } from '@mui/material/styles';

const Switch = (theme: Theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 24,
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          margin : 10
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#8A6DFF',
              opacity: 1,
              border: 'none',
            },
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        thumb: {
          width: 20,
          height: 20,
          boxShadow: 'none',
        },
        track: {
          borderRadius: 12,
          backgroundColor: '#E0E0E0',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
          margin: 0,
        },
      },
    },
  };
};

export default Switch;
