import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { CloseIcon, NotificationsIcon } from 'Icons';
import { IconButton } from '@mui/material';
import NotificationGroup from './NotificationGroup';
import NotificationTabs from './NotificationTabs';
import useLocales from '@/hooks/useLocales';
import { useEffect, useState } from 'react';
import { Notice } from '@/types/notice';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import noticeApi from 'api/notice';
import NotificationLoading from './NotificationLoading';
import ShowMore from './ShowMore';

interface Props {
  toggleDrawer: (open: boolean) => () => void;
}
const NotificationContainer: React.FC<Props> = ({ toggleDrawer }) => {
  const { translate } = useLocales();

  const cache = useQueryClient();

  // when its 0 the active tab is "All" and when its 1 the active tab is "Unread"
  const [activeTab, setActiveTab] = useState(0);

  // notice list
  const [notices, setNotices] = useState<Notice[] | null>(null);

  // the number of notices
  const [size, setSize] = useState<number>(10);

  // get list of notices depend on {size : "number of notices" , is_read : "if it was false the request give us unread notices"}
  const { data, isFetching: listLoading } = useQuery(
    ['notice_list', !activeTab, size],
    () =>
      noticeApi.getNotices({
        page_size: size,
        ...(activeTab && { is_read: !activeTab }),
      }),
    {
      enabled: true,
      onSuccess(data) {
        data?.data.success && setNotices(data.data.data.list);
      },
      onError() {
        setNotices([]);
      },
      keepPreviousData: true,
      retry: false,
    },
  );

  // mark all notices as read
  const { isLoading: markAllLoading, mutate } = useMutation({
    mutationFn: () => noticeApi.markAllAsRead(),
    onSuccess: () => {
      cache.invalidateQueries({
        queryKey: ['notice_list', !activeTab, size],
      });
      cache.invalidateQueries({
        queryKey: ['has_notice'],
      });
    },
  });

  // clear prev data when a tab is changed
  useEffect(() => {
     setNotices([]);
  }, [activeTab]);

  return (
    <Box
      component="section"
      width={{
        sm: '100%',
        md: '440px',
        lg: '480px',
      }}
      height={'100%'}
      sx={{ overflowY: 'scroll' }}
    >
      <Stack spacing={1} width={'100%'} divider={<Divider />}>
        <Grid
          container
          width={'100%'}
          justifyContent="space-between"
          alignItems={'center'}
          sx={{
            paddingX: '24px',
            position: 'sticky',
            top: 0,
            bgcolor: 'white',
            zIndex: '1030',
            paddingTop: '12px',
          }}
        >
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <NotificationsIcon style={{ fontSize: '24px' }} />
              </Grid>
              <Grid item>
                <Typography component={'span'} fontSize={16} fontWeight={600}>
                  {translate('common.notifications.notification_title')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              size="small"
              onClick={toggleDrawer(false)}
              sx={{ border: '#D3D5D9 1px solid', borderRadius: '8px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid rowSpacing={1} container>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ paddingX: '24px' }}
            >
              <Grid item>
                <NotificationTabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={
                    markAllLoading || !notices?.some((item) => !item.is_read)
                  }
                  onClick={() => mutate()}
                >
                  {translate('common.notifications.mark_all_as_read')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={2}
              sx={{
                paddingX: '24px',
                marginTop: '10px',
              }}
            >
              <NotificationGroup notifications={notices ? notices : []} />
              {listLoading && <NotificationLoading />}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent={'center'}
            sx={{
              marginY: '20px',
            }}
          >
            {!listLoading && notices?.length ? (
              <ShowMore
                size={size}
                total={
                  data?.data?.data.total_item ? data?.data?.data.total_item : 0
                }
                setSize={setSize}
                step={10}
              />
            ) : (
              null
            )}
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default NotificationContainer;
