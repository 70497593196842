import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const LoadingButton = (theme: Theme) => ({
  MuiLoadingButton: {
    styleOverrides: {
      loadingIndicator: {
        // color: theme.palette.primary.light,
      },
      root: {
        '&.MuiButton-destructiveFill': {
          '>.MuiLoadingButton-loadingIndicator': {
            color: 'white',
          },
        },
        '&.MuiButton-confirm': {
          '>.MuiLoadingButton-loadingIndicator': {
            color: 'white',
          },
        },
        '&.MuiButton-hightlight': {
          '>.MuiLoadingButton-loadingIndicator': {
            color: 'white',
          },
        },
        '&.MuiButton-warning': {
          '>.MuiLoadingButton-loadingIndicator': {
            color: theme.palette.grey[700],
          },
        },
        '&.MuiButton-text': {
          '& .MuiLoadingButton-startIconPendingStart': {
            marginLeft: 0,
          },
          '& .MuiLoadingButton-endIconPendingEnd': {
            marginRight: 0,
          },
        },
      },
    },
  },
});

export default LoadingButton;
