import Loadable from 'components/Loadable';
import { lazy } from 'react';

const SegmentList = Loadable(lazy(() => import('./List/SegmentList')));

const CreateSegment = Loadable(lazy(() => import('./Create/CreateSegment')));

const DetailSegment = Loadable(lazy(() => import('./Detail/DetailSegment')));

const UpdateSegment = Loadable(lazy(() => import('./Update/UpdateSegment')));

export { SegmentList, CreateSegment, DetailSegment, UpdateSegment };
