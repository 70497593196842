import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Theme, useTheme } from '@mui/material';

// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

// config
import { HEADER, NAVBAR } from '../../config';

// import NavbarVertical from './navbar/NavbarVertical';
import Sidebar from './Sidebar';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }: { collapseClick: boolean; theme: Theme }) => ({
  flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 24 * 2,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 16,
    // paddingRight: 16,
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24 * 5,
    paddingBottom: 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({
  children,
}: {
  children?: JSX.Element;
}): JSX.Element {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();

  const [open, setOpen] = useState(false);

  const toggleSidebar = () => setOpen((prev) => !prev);

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {/* <NavbarVertical isOpenSidebar={open} toggleSidebar={toggleSidebar} /> */}
      <Sidebar open={open} toggle={toggleSidebar} />
      <MainStyle collapseClick={collapseClick} theme={theme}>
        {children || <Outlet />}
      </MainStyle>
    </Box>
  );
}
