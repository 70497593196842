import axios from 'axios';
import { Response } from '../@types/api';
import { BusinessProfile, UserProfile } from '../@types/user';
import API_ROUTES from '../api_endpints';

type LoginResponse = Response<{
  access: string;
  refresh: string;
  profile: BusinessProfile;
  user: UserProfile;
}>;

const login = async (email: string, password: string) =>
  await axios.post<LoginResponse>(API_ROUTES.business.login, {
    email,
    password,
  });

const logout = async (token: string) =>
  await axios.post(
    API_ROUTES.logout,
    {
      token,
    },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );

const refreshToken = async (rToken: string) =>
  await axios.post(API_ROUTES.business.refreshToken, {
    refresh_token: rToken,
  });

const resetPassword = async (data: { email: string }) => {
  try {
    return await axios.post(API_ROUTES.resetPassword, data);
  } catch (err) {
    return { status: false };
  }
};

const confirmPassword = async (id: string, token: string, password: string) =>
  await axios.post(API_ROUTES.confirmPassword(id, token), {
    password,
  });

export default {
  login,
  logout,
  refreshToken,
  resetPassword,
  confirmPassword,
};
