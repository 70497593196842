import { QueryClient } from '@tanstack/react-query';

export const QueryStaleTime = 1000 * 60 * 5; // 5min

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
