import { Typography, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import { AlertIcon, CheckIcon, CloseIcon } from '../Icons';

export const showSuccessMessage = ({ message }: { message: string }) => {
  toast(
    <Typography fontWeight={600} fontSize={16} color="#232833">
      {message}
    </Typography>,
    {
      closeOnClick: true,
      closeButton: <CloseIcon />,
      icon: () => <CheckIcon color="#6BB219" />,
    },
  );
};

export const showErrorMessage = ({ message }: { message: string }) => {
  toast(
    <Typography fontWeight={600} fontSize={16} color="#232833">
      {message}
    </Typography>,
    {
      closeOnClick: true,
      closeButton: <CloseIcon />,
      icon: () => <AlertIcon color="#EB2A37" />,
    },
  );
};
