import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const List = (theme: Theme) => ({
  // MuiList: {
  // 	styleOverrides: {
  // 		root: {
  // 			color: 'white !important',
  // 		},
  // 	},
  // },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        color: 'white !important',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        minWidth: 'auto',
        marginRight: theme.spacing(2),
      },
    },
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      multiline: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
});

export default List;
