import { Color } from '@mui/material';

const YELLOW: Color = {
  50: '',
  100: '#FEFCE8',
  200: '#FEF9C3',
  300: '#FEF08A',
  400: '#FACC15',
  500: '#EAB308',
  600: '#CA8A04',
  700: '#A16207',
  800: '#854D0E',
  900: '#713F12',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default YELLOW;
