import useLocales from '@/hooks/useLocales';
import { Tab, Tabs } from '@mui/material';
import React from 'react';
interface Props {
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
}
const NotificationTabs: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const { translate } = useLocales();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <Tabs
      role="navigation"
      value={activeTab}
      onChange={handleChange}
      sx={{
        // .css-h8ma28-MuiButtonBase-root-MuiTab-root
        '& .MuiTab-root': {
          borderRadius: 6,
          paddingX: '10px !important',
          paddingY: '5px !important',
        },
        '& .MuiTabs-root': {
          borderRadius: 6,
          padding: '7px',
        },
        '& .MuiTabs-indicator': {
          display: 'none',
          padding: '3px',
        },
        '& .Mui-selected': {
          backgroundColor: 'white',
          borderRadius: '6px',
          color: 'CaptionText !important',
        },
        backgroundColor: 'ButtonShadow',
        borderRadius: '7px',
        padding: '3px',
        minHeight: '0',
      }}
    >
      <Tab
        sx={{
          fontSize: '13px',
          minWidth: '30px',
          minHeight: '30px !important',
        }}
        label={translate('common.notifications.all')}
      />
      <Tab
        sx={{
          fontSize: '13px',
          minWidth: '30px',
          minHeight: '30px !important',
        }}
        label={translate('common.notifications.unread')}
      />
    </Tabs>
  );
};

export default NotificationTabs;
