import { Suspense } from 'react';
import RequestProgress from './RequestProgress';
import { Box } from '@mui/material';

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense
      fallback={
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <RequestProgress />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
