import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, StackProps, Stack } from '@mui/material';
// ----------------------------------------------------------------------

interface Props extends StackProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  disablePadding?: boolean;
}
const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', meta, disablePadding = false, ...other }, ref) => {
    return (
      <>
        <Helmet>
          <title>{`${title} | Dashboard`}</title>
          {meta}
        </Helmet>

        <Stack
          ref={ref}
          {...other}
          width="100%"
          justifyContent="space-between"
          flexDirection="column"
          sx={{
            padding: disablePadding
              ? 0
              : {
                  md: '0 40px',
                  xs : '0 10px'
                },
          }}
          gap={2}
        >
          <>{children}</>
        </Stack>
      </>
    );
  },
);

export default Page;
