import { useAtom } from 'jotai';

import { settingAtom } from '../atoms/settingAtom';

export const useAtomSetting = () => {
  const [setting, setSetting] = useAtom(settingAtom);

  const setFirstRender = () => {
    setSetting(() => ({
      coins: setting.coins,
      settings: {
        ...setting.settings,
        is_first_fender_after_login: true,
      },
    }));
  };

  return { ...setting, setFirstRender };
};
