import React, { useState } from 'react';
import Button from '@mui/material/Button';
import useLocales from '@/hooks/useLocales';

interface ShowMoreProps {
  size: number;
  step: number;
  setSize: (size: number) => void;
  total: number;
}

const ShowMore: React.FC<ShowMoreProps> = ({ size, setSize, total, step }) => {
  const { translate } = useLocales();

  const handleShowMore = () => {
    setSize(size + step);
  };

  return size < total ? (
    <Button onClick={handleShowMore} sx={{ marginTop: 1 }}>
      {translate('common.notifications.showMore')}
    </Button>
  ) : null;
};

export default ShowMore;
