import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import MenuIcons from 'Icons/MenuIcons';
import MyAvatar from 'components/MyAvatar';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import Notifications from './notifications';
import useLocales from '@/hooks/useLocales';
import MenuIcon from '@mui/icons-material/Menu';
import useFilteredRoutes from './useFilteredRoutes';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from '@/hooks/useAuth';
import { APP_VERSION } from 'config';

const itemStyle = {
  cursor: 'pointer',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '5px',
  width: '100%',
};

const Sidebar = ({ open, toggle }: { open: boolean; toggle: () => void }) => {
  const theme = useTheme();
  const { currentLang, translate } = useLocales();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    navigate('/login');
    logout();
  };

  const activeSubMenuStyle = {
    backgroundColor: '#EFECFF',
    color: theme.palette.primary.main,
  };

  const { list: mainRoutes } = useFilteredRoutes();

  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [active, setActive] = useState<number>(0);
  const [hover, setHover] = useState<number>(0);
  const [isActiveChild, setIsActiveChild] = useState<string | null>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    const normalizedPathname = pathname.replace('/dashboard/', '');

    let foundRoute = null;

    for (const route of mainRoutes) {
      if (route.keys.some((key) => normalizedPathname.startsWith(key))) {
        foundRoute = route;
        break;
      }
    }

    if (foundRoute) {
      setActive(foundRoute.id);
      const activeChild = foundRoute.children
        .filter((child) => !child.disable)
        .sort((a, b) => b.path.length - a.path.length)
        .find((child) => {
          const childBasePath = child.path.replace('/dashboard/', '');
          return (
            normalizedPathname === childBasePath ||
            normalizedPathname.startsWith(childBasePath)
          );
        });

      if (activeChild) {
        setIsActiveChild(activeChild.id);
      } else {
        setIsActiveChild(null);
      }
    }
  }, [pathname, mainRoutes]);

  const handlePreventLink = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <Box
        onClick={toggle}
        sx={{
          display: { xs: 'auto', lg: 'none' },
          position: 'fixed',
          top: 10,
          left: 10,
          zIndex: 1200,
        }}
      >
        <IconButton>
          <MenuIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {open ? (
        <Box
          onClick={toggle}
          sx={{
            position: 'fixed',
            zIndex: 1201,
            width: '100vw',
            height: '100%',
            top: 0,
            left: 0,
            background: 'linear-gradient(360deg, #000000, #ffffff);',
            opacity: 0.7,
            display: {
              xs: 'block',
              lg: 'none',
            },
          }}
        ></Box>
      ) : null}

      <Box
        sx={{
          transition: 'all 0.2s ease',
          width: {
            xs: '280px',
            lg: '280px',
            // openSubMenu &&
            // mainRoutes.find((item) => item.id === active)?.children?.length
            //   ? '280px'
            //   : '70px',
          },
          height: '100vh',
          position: {
            xs: 'absolute',
            lg: 'relative',
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: '280px',
              lg: '280px',
              // openSubMenu &&
              // mainRoutes.find((item) => item.id === active)?.children?.length
              //   ? '280px'
              //   : '70px',
            },
            height: '100vh',
            position: { xs: 'fixed', lg: 'fixed' },
            borderRight: `1px solid ${theme.palette.grey[200]}`,
            borderLeft: `1px solid ${theme.palette.grey[200]}`,
            zIndex: 1202,
            left: {
              xs: open ? 0 : '-280px',
              lg: 'auto',
            },
            transition: 'all 0.2s ease',
            top: 0,
            display: 'flex',
            alignItems: 'stretch',
            backgroundColor: theme.palette.background.paper,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
          }}
          onMouseEnter={() => setOpenSubMenu(true)}
          onMouseLeave={() => setOpenSubMenu(false)}
        >
          <Box
            sx={{
              width: '70px',
              borderRight: `1px solid ${theme.palette.grey[200]}`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '1rem',
                gap: '0.7rem',
              }}
            >
              <Box sx={{ cursor: 'pointer', padding: '12px' }}>
                <Link to="/">
                  <img src="/logo/logo.png" alt="logo" />
                </Link>
              </Box>

              {mainRoutes?.map((item, index) => {
                const isActive = active === item.id;
                const isHover = hover === item.id;
                return (
                  <Tooltip
                    key={index}
                    title={item.name}
                    placement={currentLang?.value === 'fa' ? 'left' : 'right'}
                  >
                    <Link
                      onMouseEnter={() => setHover(item?.id)}
                      onMouseLeave={() => setHover(0)}
                      to={item?.path}
                      style={
                        isActive || isHover
                          ? { ...itemStyle, backgroundColor: '#EFECFF' }
                          : itemStyle
                      }
                    >
                      <MenuIcons
                        key={item.id}
                        name={item?.iconName}
                        color={isActive ? '#5236B0' : undefined}
                      />
                    </Link>
                  </Tooltip>
                );
              })}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1rem',
              }}
            >
              <Tooltip
                title={translate('common.notifications.notification_title')}
                placement={currentLang?.value === 'fa' ? 'left' : 'right'}
              >
                <Box sx={itemStyle}>
                  <Notifications />
                </Box>
              </Tooltip>
              <Box sx={itemStyle}>
                <Tooltip
                  title={translate('profile_page.title')}
                  placement={currentLang?.value === 'fa' ? 'left' : 'right'}
                >
                  <Link to={PATH_DASHBOARD.user.profile}>
                    <MyAvatar />
                  </Link>
                </Tooltip>
              </Box>
              <Box sx={itemStyle}>
                <Tooltip
                  title={translate('common.logout')}
                  placement={currentLang?.value === 'fa' ? 'left' : 'right'}
                >
                  <IconButton color="error" onClick={handleLogout}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Stack
            sx={{
              width: {
                xs: '75%',
                // lg: openSubMenu ? '75%' : '0px',
                lg: '75%',
              },
              transition: 'all 0.3s ease',
              overflow: 'hidden',
            }}
            justifyContent="space-between"
          >
            <Box
              sx={{
                width: '210px',
                padding: '1rem',
                paddingTop: '1.5rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <Typography
                fontWeight={600}
                fontSize={20}
                sx={{ marginBottom: '1rem' }}
              >
                {mainRoutes.find((item) => item.id === active)?.title}
              </Typography>
              {mainRoutes
                ?.find((item) => item.id === active)
                ?.children?.map((child, cinedx) => {
                  return (
                    <Link
                      key={cinedx}
                      to={child.path}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                      onClick={
                        child.disable
                          ? handlePreventLink
                          : () => {
                              setIsActiveChild(child.id);
                              toggle();
                            }
                      }
                    >
                      <Typography
                        color={
                          child.disable ? theme.palette.grey[300] : '#45464F'
                        }
                        sx={{
                          width: '100%',
                          padding: '0.5rem',
                          borderRadius: '7px',
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            backgroundColor: !child.disable
                              ? '#EFECFF'
                              : 'transparent',
                          },
                          ...(isActiveChild === child.id
                            ? activeSubMenuStyle
                            : {}),
                        }}
                      >
                        {child.name}
                      </Typography>
                    </Link>
                  );
                })}
            </Box>
            <Typography textAlign="center" padding={2}>
              version . {APP_VERSION}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
