import Loadable from 'components/Loadable';
import { lazy } from 'react';

const NotificationsList = Loadable(
  lazy(() => import('./List/NotificationsList')),
);
const CreateNotification = Loadable(
  lazy(() => import('./Create/CreateNotification')),
);

const UpdateNotification = Loadable(
  lazy(() => import('./Update/UpdateNotification')),
);

export { NotificationsList, CreateNotification, UpdateNotification };
