// hooks
// import { displayName } from 'react-quill';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------
// displayName:any;

const MyAvatar = ({ ...other }: AvatarProps) => {
  const { user } = useAuth();
  const displayName: any = user?.first_name;

  return (
    <Avatar
      src={user?.avatar}
      alt={user?.first_name}
      color={user?.avatar ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </Avatar>
  );
};

export default MyAvatar;
