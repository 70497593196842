import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Avatar = (theme: Theme) => ({
  MuiAvatar: {
    styleOverrides: {
      colorDefault: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[400],
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      avatar: {
        fontSize: 16,
        fontWeight: theme.typography.fontWeightMedium,
        '&:first-of-type': {
          fontSize: 14,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter,
        },
      },
    },
  },
});

export default Avatar;
