import { createRoot } from 'react-dom/client';

// highlight
import './utils/highlight';

// editor
import 'react-quill/dist/quill.snow.css';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { HelmetProvider } from 'react-helmet-async';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { queryClient } from 'utils/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';

// ----------------------------------------------------------------------

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <App />
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  </QueryClientProvider>,
);
