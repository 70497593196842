import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Timeline = (theme: Theme) => ({
  MuiTimelineDot: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },

  MuiTimelineConnector: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.divider,
      },
    },
  },
});

export default Timeline;
