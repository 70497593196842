import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Link = (theme: Theme) => ({
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
});

export default Link;
