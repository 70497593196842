import { alpha, Theme } from '@mui/material/styles';
import { BLUE } from '../desing_system/colors';
//
import { CloseIcon } from './CustomIcons';

// ----------------------------------------------------------------------

const Chip = (theme: Theme) => ({
  MuiChip: {
    defaultProps: {
      deleteIcon: <CloseIcon />,
    },

    styleOverrides: {
      root: {
        // minWidth: '100px',
        width: 'max-content',
        padding: '0 8px',
        fontSize: '12px',
        borderWidth: 1,
        fontWeight: 'bold',
        borderStyle: 'solid',
        borderRadius: '8px',
      },
      colorDefault: {
        '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
          color: theme.palette.text.secondary,
        },
      },
      filled: {
        '&.MuiChip-colorDefault': {
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.grey[100],
          // borderColor: theme.palette.grey[300],
          border: 'none',
        },
        '&.MuiChip-colorSuccess': {
          color: theme.palette.success.darker,
          backgroundColor: theme.palette.success.lighter,
          // borderColor: theme.palette.success.light,
          border: 'none',
        },
        '&.MuiChip-colorInfo': {
          color: BLUE[700],
          backgroundColor: BLUE[100],
          // borderColor: BLUE[200],
          border: 'none',
        },
        '&.MuiChip-colorSecondary': {},
        '&.MuiChip-colorError': {
          color: theme.palette.error.dark,
          backgroundColor: theme.palette.error.lighter,
          // borderColor: theme.palette.error.light,
          border: 'none',
        },
      },
      outlined: {
        borderColor: alpha(theme.palette.grey[500], 0.32),
        '&.MuiChip-colorPrimary': {
          borderColor: theme.palette.primary.main,
        },
        '&.MuiChip-colorSecondary': {
          borderColor: theme.palette.secondary.main,
        },
      },
      //
      avatarColorInfo: {
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.dark,
      },
      avatarColorSuccess: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.dark,
      },
      avatarColorWarning: {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.dark,
      },
      avatarColorError: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.dark,
      },
    },
  },
});

export default Chip;
