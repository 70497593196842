import { Color } from '@mui/material';

const BLUE: Color = {
  50: '',
  100: '#DBEAFE',
  200: '#BFDBFE',
  300: '#93C5FD',
  400: '#60A5FA',
  500: '#3B82F6',
  600: '#2563EB',
  700: '#1D4ED8',
  800: '#1E40AF',
  900: '#1E3A8A',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default BLUE;
