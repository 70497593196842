import { RoleApiResponse } from '../@types/role';
import useAuth from 'hooks/useAuth';
import { ReactNode } from 'react';
import ForbiddenComponent from 'components/Forbidden';

const AccessGuard: React.FC<{
  children: ReactNode;
  page: keyof RoleApiResponse['permissions'];
  role: string;
}> = ({ children, page, role }) => {
  const { user } = useAuth();

  const hasAccess =
    typeof user?.role !== 'string' &&
    user?.role?.permissions[page]?.includes(role); // Added optional chaining

  return user?.role !== null && !hasAccess ? (
    <>
      <ForbiddenComponent
        title="Access Denied!"
        description="You have no permission to access this page."
      />
    </>
  ) : (
    <>{children}</> // Using the shorthand fragment syntax to wrap children
  );
};

export default AccessGuard;
