//
//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

const Select = () => ({
  MuiSelect: {
    defaultProps: {
      IconComponent: InputSelectIcon,
    },
  },
});

export default Select;
