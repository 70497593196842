import axios from './axios';

// ----------------------------------------------------------------------

const setSession = (
  accessToken: string | null,
  refreshToken: string | null,
) => {
  if (accessToken && refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export default setSession;
