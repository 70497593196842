import { TokenOptions } from '..';

const Token: TokenOptions = {
  button_border: 1,
  button_border_radius: 10,
  button_border_color: 'transparent',
  button_font_size: 14,
};

export default Token;
