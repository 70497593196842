import { Color } from '@mui/material';

const WHITE: Color = {
  50: '',
  100: '#FFFFFF',
  200: '#FFFFFF',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#FFFFFF',
  600: '#FFFFFF',
  700: '#FFFFFF',
  800: '#FFFFFF',
  900: '#FFFFFF',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default WHITE;
