import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Response } from '../@types/api';
import { ErrorMessage, ERROR_TYPE } from '../Errors';
import { HOST_API } from '../config';
import { showErrorMessage } from './alerts';
import authApi from 'api/auth.api';
import setSession from './jwt';
import i18next from 'i18next';
const TIMEOUT = 30000;

const axiosInstance = axios.create({
  baseURL: HOST_API,
  timeout: TIMEOUT,
});

interface CustomAxiosRequestConfig<T = any> extends AxiosRequestConfig {
  sent?: boolean;
}

const errorMessages: Record<number, ERROR_TYPE> = {
  400: ErrorMessage(400),
  401: ErrorMessage(401),
  402: ErrorMessage(402),
  403: ErrorMessage(403),
  404: ErrorMessage(404),
  405: ErrorMessage(405),
  408: ErrorMessage(408),
  429: ErrorMessage(429),
  500: ErrorMessage(500),
};

const handleErrorResponse = async (error: AxiosError<Response>) => {
  if (error.code === 'ECONNABORTED') {
    showErrorMessage({ message: errorMessages[408].message });
    return Promise.reject(errorMessages[408]);
  }

  const responseStatus = error.response?.status;
  const rToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  if (
    responseStatus === 401 &&
    !(error.config as CustomAxiosRequestConfig).sent &&
    rToken &&
    accessToken
  ) {
    // Mark the request as "sent" to avoid infinite loops
    (error.config as CustomAxiosRequestConfig).sent = true;

    try {
      // Attempt to refresh the token

      const { data } = await authApi.refreshToken(rToken);
      const access = data?.data?.access;

      setSession(access, rToken);

      // window.location.reload();
      const axiosPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(axiosInstance(error.config!));
        }, 1000);
      });

      // Wait for axiosInstance to complete before continuing
      return axiosPromise;
    } catch (refreshError) {
      // Handle token refresh error
      showErrorMessage({ message: 'Failed to refresh token' });
      setSession(null, null);
      window.location.reload();
      return Promise.reject(refreshError);
    }
  }

  if (
    error.response?.data?.non_field_errors &&
    error.response?.data?.non_field_errors.length > 0
  ) {
    error.response?.data?.non_field_errors.forEach((err) =>
      showErrorMessage({ message: err }),
    );

    return Promise.reject();
  }

  if (responseStatus && errorMessages[responseStatus]) {
    const errorMessage = error.response?.data.error;
    if (typeof errorMessage === 'string') {
      const errorCode = ErrorMessage(errorMessage);
      showErrorMessage({ message: i18next.t(errorCode.message) });
      return Promise.reject(errorCode);
    } else if (typeof errorMessage === 'object') {
      return Promise.reject(errorMessage);
    }
    showErrorMessage({ message: i18next.t(errorMessages[responseStatus].key) });
    return Promise.reject(errorMessages[responseStatus]);
  }

  showErrorMessage({ message: 'An error occurred' });
  return Promise.reject(new Error('error not handled'));
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the access token from local storage
    const accessToken = localStorage.getItem('accessToken');

    // If an access token exists, add it to the request headers
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use((response: AxiosResponse<Response>) => {
  // You can add any processing of the response here
  return response;
}, handleErrorResponse);

export const responseBody = (response: AxiosResponse) => response.data;

export default axiosInstance;
