import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const TablePagination = (theme: Theme) => ({
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '&.MuiInputBase-root': {
          backgroundColor: 'red',
        },
      },
      actions: {
        display: 'flex',
        gap: 8,
        '.MuiButtonBase-root': {
          width: 32,
          height: 32,
          border: `1px solid ${theme.palette.grey[100]}`,
          boxShadow: '0 1px 1px rgba(35, 43, 51, 0.12)',
          borderRadius: 8,
        },
      },
      displayedRows: {
        fontWeight: 500,
        color: theme.palette.grey[500],
      },
      selectLabel: {
        fontWeight: 500,
        color: theme.palette.grey[500],
      },
      select: {
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: 8,
      },
      toolbar: {
        minHeight: '0px !important',
      },
    },
  },
});

export default TablePagination;
