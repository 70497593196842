import { UserProfile } from './user';

export enum noticeTypes {
  WARNING = 0,
  ERROR = 1,
  INFO = 2,
  PAYMENT = 3,
  OBJECT = 4,
}

export enum noticeContentTypes {
  NOTE = 'note',
  MEDIALIBRARY = 'medialibrary',
  REWARD = 'reward',
}

export type Notice = {
  id: string;
  created: string;
  updated: string;
  subject: string;
  content?: string;
  is_read: boolean;
  sender: UserProfile | null;
  content_type: {
    name:
      | noticeContentTypes.MEDIALIBRARY
      | noticeContentTypes.NOTE
      | noticeContentTypes.REWARD;
  };
  notice_type: noticeTypes;
  attachment_file_path: string | null;
  object_id: number;
};
