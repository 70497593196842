import { IconProps } from '../@types/icon';

const AlertIcon = ({
  color = '#EB2A37',
  width = 18,
  height = 17,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4808 0.852915C9.53934 0.427812 8.46058 0.427813 7.5191 0.852915L7.82774 1.53647L7.5191 0.852915C6.89958 1.13265 6.4271 1.66064 5.97423 2.3198C5.52293 2.97667 5.02183 3.87151 4.38842 5.00261L4.36905 5.0372L2.17514 8.95491L2.15592 8.98922L2.15592 8.98923L2.15588 8.98928C1.54753 10.0756 1.0653 10.9367 0.752016 11.6401C0.436759 12.3479 0.239441 13.0064 0.314932 13.6663C0.430091 14.673 0.964345 15.5845 1.78644 16.1769C2.32535 16.5652 2.99625 16.7147 3.76785 16.7855C4.53463 16.8558 5.52162 16.8558 6.76674 16.8558H6.80604H11.1939H11.2332C12.4783 16.8558 13.4653 16.8558 14.2321 16.7855C15.0037 16.7147 15.6746 16.5652 16.2135 16.1769C17.0356 15.5845 17.5698 14.673 17.685 13.6663C17.7605 13.0063 17.5632 12.3479 17.2479 11.6401C16.9346 10.9367 16.4524 10.0755 15.844 8.98917L15.8248 8.9549L13.6309 5.0372L13.6115 5.00263C12.9781 3.87153 12.477 2.97667 12.0257 2.3198C11.5728 1.66064 11.1003 1.13265 10.4808 0.852915ZM8.99997 5.625C9.41418 5.625 9.74997 5.96078 9.74997 6.375V9.375C9.74997 9.78921 9.41418 10.125 8.99997 10.125C8.58575 10.125 8.24997 9.78921 8.24997 9.375V6.375C8.24997 5.96078 8.58575 5.625 8.99997 5.625ZM8.99997 12.75C9.41418 12.75 9.74997 12.4142 9.74997 12C9.74997 11.5858 9.41418 11.25 8.99997 11.25C8.58575 11.25 8.24997 11.5858 8.24997 12C8.24997 12.4142 8.58575 12.75 8.99997 12.75Z"
      fill={color}
    />
  </svg>
);

export default AlertIcon;
