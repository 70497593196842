import { Color } from '@mui/material';

const ERROR: Color = {
  50: '',
  100: '#FEE0E2',
  200: '#FFC7CA',
  300: '#FF8F96',
  400: '#F55863',
  500: '#EB2A37',
  600: '#D11925',
  700: '#B30E19',
  800: '#8F0611',
  900: '#66000A',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default ERROR;
