import { QueryParams } from './@types/api';
import { ChangePointsParams } from './@types/customer';
import { HOST_API } from './config';

const API_VERSION = '/v1';

const PATH = (path: string): string => `${HOST_API}${API_VERSION}/api/${path}/`;

// TODO: use object proxy
const API_ROUTES = {
  // user authentication api
  login: PATH('accounts/business/profile/login'),
  logout: PATH('accounts/logout'),
  register: PATH('accounts/register'),
  loginOTPCode: PATH('accounts/verify'),
  refreshToken: PATH('accounts/token/refresh'),
  loginUserReview: PATH('accounts/auth'),
  loginSetPassword: PATH('accounts/set-password'),
  loginChangePassword: PATH('accounts/change-password'),
  changeAvatar: PATH('accounts/avatar'),
  generateReferralCode: PATH('accounts/referral'),
  getReferralScoresInvited: PATH('accounts/referral'),
  resetPassword: PATH('accounts/business/user/reset-password'),
  confirmPassword: (id: string, token: string) =>
    PATH(`accounts/business/user/confirm-password/${id}/${token}`),

  overview: PATH('overview/business'),

  upload: PATH('media-library/upload'),
  getImage: (path: string) => HOST_API.concat(path),

  accounts: {
    business: {
      settings: {
        root: PATH('accounts/business/general-settings/detail'),
        integration: PATH('accounts/business/settings/integrations/detail'),
        update: PATH('accounts/business/general-settings/update'),
      },
      integrations: {
        sms: PATH('notification/integration/sms/update'),
        email: PATH('notification/integration/email/update'),
        getInstagram: PATH('instagram/detail'),
        createInstagram: PATH('instagram/create'),
        updateInstagram: PATH('instagram/update'),
        activationMailGun: PATH(
          'notification/integration/email/mailgun/activate',
        ),
        submitActivationMailgun: (name: string, user_id: string) =>
          PATH(
            `notification/integration/email/mailgun/activate/verify/${name}/${user_id}`,
          ),
        whmcs: {
          testConnection: PATH('shop/test-connection'),
        },
        googleAnalytics: {
          detail: PATH('ga4/credentials/detail'),
          create: PATH('ga4/credentials/create'),
          update: PATH('ga4/credentials/update'),
        },
        googleService: PATH('google-integration/business/credential/upload'),
        googleAuth: PATH('google-integration/business/auth/update'),
        googleJsonFile: (path: string) => PATH(`${path}`),
        googleAnalytic: PATH(
          'google-integration/business/credential/ga4/update',
        ),
        googleForm: PATH('google-integration/business/credential/form/update'),
      },
      profile: {
        root: PATH('accounts/business/profile/detail'),
        update: PATH('accounts/business/profile/update'),
        users: {
          update: (hash: string) =>
            PATH(`accounts/business/profile/users/update/${hash}`),
          create: PATH('accounts/business/profile/users/create'),
          list: PATH('accounts/business/profile/users/list'),
          detail: (id: string | number) =>
            PATH(`accounts/business/profile/users/detail/${id}`),
        },
      },
      user: {
        detail: PATH('accounts/business/user/detail'),
        update: PATH('accounts/business/user/update'),
        changePassword: PATH('accounts/business/user/change-password'),
        delete: (id: string | number) =>
          PATH(`accounts/business/profile/users/delete/${id}`),
      },
      role: {
        list: PATH('accounts/business/roles/list'),
        details: (id: number | string) =>
          PATH(`accounts/business/roles/detail/${id}`),
        create: PATH('accounts/business/roles/create'),
        update: (id: number | string) =>
          PATH(`accounts/business/roles/update/${id}`),
        delete: (id: number | string) =>
          PATH(`accounts/business/roles/delete/${id}`),
      },

      history: {
        list: PATH('accounts/business/history/list'),
      },
    },
    config: PATH('accounts/config'),
  },

  referral: {
    list: PATH('referral/business/list'),
    create: PATH('referral/business/create/'),
    reOrdering: PATH(`referral/business/update/position`),
    details: (id: number | string) => PATH(`referral/business/detail/${id}`),
    update: (id: number | string) => PATH(`referral/business/update/${id}`),
    delete: (id: number | string) => PATH(`referral/business/delete/${id}`),
  },

  segments: {
    list: PATH('segment/business/list'),
    detail: (id: number | string) =>
      PATH(`segment/business/customer/list/${id}`),
    detail_info: (id: number | string) => PATH(`segment/business/detail/${id}`),
    create: PATH('segment/business/create/'),
    update: (id: number | string) => PATH(`segment/business/update/${id}`),
    delete: (id: number | string) => PATH(`segment/business/delete/${id}`),
    reCalculateSegments: PATH(`segment/business/calculate`),

    // reOrdering: PATH(`referral/business/update/position`),
    // details: (id: number | string) => PATH(`referral/business/detail/${id}`),
    // update: (id: number | string) => PATH(`referral/business/update/${id}`),
    // delete: (id: number | string) => PATH(`referral/business/delete/${id}`),
  },

  settings: {
    details: PATH('accounts/business/settings/detail'),
    update: PATH('accounts/business/settings/update'),
    resetFactory: PATH('accounts/business/settings/reset-factory'),
  },

  invoiceAndProduct: {
    invoice: PATH('shop/invoices/list'),
    product: PATH('shop/products/list'),
    category: PATH('shop/categories/list'),
    invoice_csv_import: PATH('media-library/uploader/invoices'),
    category_csv_import: PATH('media-library/uploader/categories'),
    product_csv_import: PATH('media-library/uploader/products'),
  },

  notification: {
    list: PATH('notification/list'),
    statisticsList: (id: string) => PATH(`notification/report/list/${id}`),
    create: PATH('notification/create'),
    details: PATH('notification/detail'),
    update: (id: number | string) => PATH(`notification/update/${id}`),
    delete: (id: number | string) => PATH(`notification/delete/${id}`),
    test: PATH(`notification/create/test`),
    predefinedList: PATH('notification/predefined/list'),
  },
  leaderboard: {
    list: PATH('tiersystem/business/leaderboard/'),
    activate: PATH('themes/leaderboard/status/'),
  },
  themes: {
    details: PATH('themes/config'),
    reset: PATH('themes/config/reset'),
    update: PATH('themes/config/update'),
    changeImage: PATH('themes/image/update'),
    deleteImage: PATH('themes/image/delete'),
    pwa: PATH('themes/pwa/create'),
    getPwa: PATH('themes/pwa/detail'),
  },

  business: {
    login: PATH('accounts/business/profile/login'),
    refreshToken: PATH('accounts/business/profile/refresh'),
    logout: PATH('accounts/business/profile/logout'),
    profile: {
      general: PATH('business/profile/general'),
      location: PATH('business/profile/location'),
      social: PATH('business/profile/social'),
    },

    coins: {
      list: PATH('pointsystem/coin/list'),
      create: PATH('pointsystem/coin/create'),
      update: (id: number) => PATH(`pointsystem/coin/update/${id}`),
      details: PATH('pointsystem/coin/detail'),
      delete: PATH('pointsystem/coin/delete'),
    },

    // customer api
    customers: {
      list: PATH('accounts/business/customer/list'),
      create: PATH('accounts/business/customer/create'),
      edit: (id: string | number) =>
        PATH(`accounts/business/customer/update/${id}`),
      delete: (id: string | number) =>
        PATH(`accounts/business/customer/delete/${id}`),
      rfm: PATH('loyal/business/segment/rfm'),
      // TODO: Integrated Types.
      changePoints: (id: ChangePointsParams['id']) =>
        PATH(`pointsystem/business/customer/free-points/${id}`),
      csv_export: PATH('accounts/business/customer/export/csv'),
      csv_import: PATH('media-library/uploader/users'),
    },
    getCustomerDetail: PATH('accounts/business/customer/detail'),

    getCustomerActivityTimeline: (id: string) =>
      PATH(`accounts/business/customer/activity/list/${id}`),
    getCustomer: PATH('business/customers'),
    getCustomerGeneralDetails: PATH('business/customers/general'),
    getCustomerCompanyDetails: PATH('business/customers/company'),
    getCustomerSocialLinks: PATH('business/customers/social'),
    getCustomerPurchase: (id: string | number) =>
      PATH(`accounts/business/customer/purchase-overview/${id}`),
    getCustomerTransactionsList: (id: string | number) =>
      PATH(`transactions/business/customer/list/${id}`),
    getClaimedRewards: (id: string | number) =>
      PATH(`accounts/business/customer/claimed-rewards/${id}`),
    getCustomerRefferals: (id: string) =>
      PATH(`referral/business/customer/report/${id}`),
    updateCustomerGeneralDetails: PATH('business/customers/general'),
    updateCustomerCompanyDetails: PATH('business/customers/company'),
    updateCustomerSocialLinks: PATH('business/customers/social'),
    checkCustomer: PATH('business/customers/checker'),
    uploadCustomerAvatar: PATH('business/customers/general'),
    confirmTransaction: (id: string | number) =>
      PATH(`transactions/business/confirm/${id}`),

    note: {
      create: (id: string) =>
        PATH(`accounts/business/customer/notes/create/${id}`),
      delete: (id: string | number) =>
        PATH(`/accounts/business/customer/notes/${id}/delete/`),
      update: (id: string | number) =>
        PATH(`/accounts/business/customer/notes/${id}/update/`),
      get: (id: string, params: QueryParams) =>
        PATH(`accounts/business/customer/notes/list/${id}`),
      detail: PATH('/accounts/business/customer/notes/detail'),
    },
  },
  // missions
  missions: {
    list: PATH('mission/business/list'),
    triggers: PATH('action/trigger'),
    flows: PATH('mission/flows'),
    detail: PATH('mission/business/detail'),
    create: PATH('mission/business/create'),
    update: (id: number) => PATH(`mission/business/update/${id}`),
    delete: (id: number) => PATH(`mission/business/delete/${id}`),
    activate: (id: number) => PATH(`mission/business/update/activate/${id}`),
    setPosition: (id: number) =>
      PATH(`mission/business/action/positions/update/${id}`),
    business: {
      flow: {
        list: PATH('mission/business/flow/list'),
        create: PATH('mission/business/flow/create'),
        detail: PATH('mission/business/flow/detail'),
        search: PATH('mission/business/flow/search'),
        update: (id: number) => PATH(`mission/business/flow/update/${id}`),
        delete: PATH('mission/business/flow/delete'),
        action: {
          remove: PATH('mission/business/flow/action/remove'),
          add: PATH('mission/business/flow/action/add'),
        },
      },
      action: {
        create: PATH('mission/business/action/purchase/create'),
        createGoogleForm: PATH('mission/business/action/google-form/create'),
        createGA4: PATH('mission/business/action/ga4/create'),
        updateGA4: (id: string | number) =>
          PATH(`mission/business/action/ga4/update/${id}`),
        updateGoogleForm: (id: string | number) =>
          PATH(`mission/business/action/google-form/update/${id}`),
        update: (id: string | number) =>
          PATH(`mission/business/action/purchase/update/${id}`),
        detail: PATH('mission/business/action/detail'),
        delete: PATH('mission/business/action/delete'),
        createInstagram: PATH('mission/business/action/instagram/create'),
        updateInstagram: (id: string | number) =>
          PATH(`mission/business/action/instagram/update/${id}`),
      },
    },
  },

  // pre mission
  preMission: {
    list: PATH('predefined-mission/business/list'),
    create: PATH('mission/business/predefined-mission/list'),
    update: (id: number | string) =>
      PATH(`predefined-mission/business/update/${id}`),
    details: (id: number | string) =>
      PATH(`predefined-mission/business/detail/${id}`),
    delete: PATH('mission/business/predefined-mission/list'),
  },

  // tiers
  tiers: {
    list: PATH('tiersystem/business/detail'),
    update: PATH('tiersystem/business/update'),
    updateGuide: PATH('tiersystem/business/guide/update'),
    details: PATH('tiersystem/business/guide/detail'),
  },

  // actions

  actions: {
    list: PATH('mission/business/action/list'),
  },

  tasks: {
    bulkPoints: {
      list: PATH('pointsystem/business/reports'),
      create: PATH('pointsystem/business/upload-report'),
    },
  },

  affiliate: {
    detail: PATH('affiliates/detail'),
    owners: PATH('affiliates/owner/list'),
    receivers: (id: string) => PATH(`affiliates/receiver/list/${id}`),
    update: PATH('affiliates/update'),
  },

  flows: {
    createQuizText: PATH('competition/quiz-text'),
    createMuiltiOptionQuiz: PATH('competition/quiz'),
    createImageOptionQuiz: PATH('competition/image-option'),
    details: PATH('mission/business/flow/detail'),
  },

  rewards: {
    list: PATH('reward/business/list'),
    voucherCodes: (id: number) => PATH(`reward/business/voucher/claimed/${id}`),
    create: PATH('reward/business/create'),
    update: PATH('reward'),
    delete: {
      gift: (id: number) => PATH(`reward/business/delete/gift/${id}`),
      voucher: (id: number) => PATH(`reward/business/delete/voucher/${id}`),
    },
    getTiers: PATH('reward/tiers'),
    getRFMLevels: PATH('reward/rfmlevels'),
    voucher: {
      details: PATH('reward/vouchers'),
      create: PATH('reward/vouchers'),
      update: PATH('reward/vouchers'),
      generateCode: PATH('reward/vouchers/generate-code'),
    },
    gift: {
      list: PATH('reward/gifts'),
      details: PATH('reward/gifts'),
      create: PATH('reward/gifts'),
      update: PATH('reward/gifts'),
      duplicate: (id: string | number) =>
        PATH(`/reward/business/gift/duplicate/${id}`),
      claimedList: (id: string | number) =>
        PATH(`reward/business/gift/claimed/${id}`),
    },
    freeShipping: {
      list: PATH('reward/freeShipping'),
      details: PATH('reward/freeShipping'),
      create: PATH('reward/freeShipping'),
      update: PATH('reward/freeShipping'),
    },
    cashBack: {
      list: PATH('reward/cashBack'),
      details: PATH('reward/cashBack'),
      create: PATH('reward/cashBack'),
      update: PATH('reward/cashBack'),
    },
    giftCard: {
      update: PATH('reward/giftCard'),
    },

    business: {
      gift: {
        create: PATH('reward/business/gift/create'),
        update: (id: number) => PATH(`reward/business/gift/update/${id}`),
        detail: (id: number) => PATH(`reward/business/detail/gift/${id}`),
      },
      voucher: {
        create: PATH('reward/business/voucher/create'),
        update: (id: number) => PATH(`reward/business/voucher/update/${id}`),
        getCodes: (id: number) => PATH(`reward/business/vouchers/${id}`),
        detail: (id: number) => PATH(`reward/business/detail/voucher/${id}`),
        csv_import: PATH('media-library/uploader/promotions'),
      },
      giftCard: {
        update: (id: number) => PATH(`reward/business/giftCard/update/${id}`),
      },
      freeShipping: {
        update: (id: number) =>
          PATH(`reward/business/freeShipping/update/${id}`),
      },
      cashBack: {
        update: (id: number) => PATH(`reward/business/cashBack/update/${id}`),
      },
      list: PATH('reward/business/list'),
      delete: (id: number) => PATH(`reward/business/delete/${id}`),
    },
  },

  media: {
    list: PATH('media-library/list'),
    upload: PATH('media-library/upload'),
  },

  contest: {
    business: {
      tagList: PATH('contest/business/tag/list'),
      quiz: {
        create: PATH('contest/business/quiz/create'),
        update: PATH('contest/business/quiz/update'),
      },
      quiztext: {
        create: PATH('contest/business/quiztext/create'),
        update: PATH('contest/business/quiztext/update'),
      },
      quizlike: {
        create: PATH('contest/business/quizlike/create'),
        update: PATH('contest/business/quizlike/update'),
      },
      imageoption: {
        create: PATH('contest/business/imageoption/create'),
        update: PATH('contest/business/imageoption/update'),
      },
      machine: {
        list: PATH('contest/business/machine/list'),
        detail: (id: number) => PATH(`contest/business/machine/detail/${id}`),
        create: PATH(`contest/business/machine/create`),
        update: (id: number) => PATH(`contest/business/machine/update/${id}`),
        delete: (id: number) => PATH(`contest/business/machine/delete/${id}`),
        duplicate: (id: number) =>
          PATH(`contest/business/machine/duplicate/${id}`),
      },
    },
  },

  // countries & provinces & cities api
  country: PATH('accounts/country'),
  countries: PATH('accounts/country'),
  province: PATH('accounts/province'),
  cities: PATH('accounts/city'),

  industries: PATH('accounts/industries'),
  subIndustries: PATH('accounts/subindustries'),
  posts: PATH('accounts/posts'),

  shop: {
    products: PATH('shop/products/list'),
  },

  notice: {
    noticeList: PATH('notice/business'),
    markAllAsRead: PATH('notice/business/mark-all'),
    hasNotice: PATH('notice/business/has-new-notice'),
  },
};

export default API_ROUTES;
