import Loadable from 'components/Loadable';
import { lazy } from 'react';

const PreMissionUpdate = Loadable(
  lazy(() => import('./UpdatePreMission/UpdatePreMission')),
);

const PreMissionList = Loadable(
  lazy(() => import('./PreMissionsList/PreMissionsList')),
);

export { PreMissionList, PreMissionUpdate };
