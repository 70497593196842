import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// .css-1agp1hl-MuiInputBase-root-MuiOutlinedInput-root
const Input = (theme: Theme) => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        '&.Mui-disabled': {
          '& svg': { color: theme.palette.text.disabled },
        },
        overflow: 'hidden',
        '&.MuiOutlinedInput-root': {
          borderRadius: 10,
        },
      },
      input: {
        '&::placeholder': {
          opacity: 1,
          color: theme.palette.text.disabled,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottomColor: alpha(theme.palette.grey[500], 0.56),
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: alpha(theme.palette.grey[500], 0.12),
        '&:hover': {
          backgroundColor: alpha(theme.palette.grey[500], 0.16),
        },
        '&.Mui-focused': {
          backgroundColor: theme.palette.action.focus,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      underline: {
        '&:before': {
          borderBottomColor: alpha(theme.palette.grey[500], 0.56),
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(theme.palette.grey[500], 0.32),
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.action.disabledBackground,
          },
        },
      },
    },
  },
});

export default Input;
