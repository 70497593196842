import axios from '../utils/axios';
import API_ROUTES from '../api_endpints';
import { HOST_API } from '../config';
import { BusinessProfile } from '../@types/business';
import { Response } from '../@types/api';
import { BusinessSettings } from '../@types/business';
import { urlToBlob } from '../utils/getFileDataByUrl';
import { GeneralSettingForm } from '../@types/settings';

const getBusinessProfile = async () =>
  await axios.get<Response<BusinessProfile>>(
    API_ROUTES.accounts.business.profile.root,
  );

const GetSocialLinks = async () => {
  try {
    const { data } = await axios.get(API_ROUTES.business.profile.social);
    if (data.data?.avatar) {
      data.data.avatar = HOST_API.concat(data?.data?.avatar);
    }
    return data;
  } catch (err) {
    console.log(err);
  }
};

// upload customer avatar
const UploadAvatar = async (avatar: File) => {
  const formData = new FormData();
  formData.append('avatar', avatar);
  try {
    const { data } = await axios.put(
      API_ROUTES.business.profile.general,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    if (data.data?.avatar) {
      data.data.avatar = HOST_API.concat(data?.data?.avatar);
    }

    return data;
  } catch (err) {
    if (err?.error) {
      return err;
    }
    return { status: err.response.status, statusText: err.response.statusText };
  }
};

// update customer general information like first name, last_name, email and ...etc
const UpdateGeneralDetails = async (businessData: object) => {
  try {
    const { data } = await axios.put(
      API_ROUTES.business.profile.general,
      businessData,
    );
    if (data.data?.avatar) {
      data.data.avatar = HOST_API + data.data.avatar;
    }
    return data;
  } catch (err) {
    console.log(err);
    if (err?.error) {
      return err;
    }
    return { status: err.response.status, statusText: err.response.statusText };
  }
};

// update customer general information like first name, last_name, email and ...etc
const UpdateBusinessSocialLinks = async (businessData: object) => {
  try {
    const { data } = await axios.put(
      API_ROUTES.business.profile.social,
      businessData,
    );
    return data;
  } catch (err) {
    console.log(err);
    if (err?.error) {
      return err;
    }
    return { status: err.response.status, statusText: err.response.statusText };
  }
};

// update customer location information like country, province, city and ...etc
const UpdateLocation = async (businessData: object) => {
  try {
    const { data } = await axios.put(
      API_ROUTES.business.profile.location,
      businessData,
    );
    return data;
  } catch (err) {
    console.log(err);
    if (err?.error) {
      return err;
    }
    return { status: err.response.status, statusText: err.response.statusText };
  }
};

const updateBusinessProfile = async (data: BusinessProfile) =>
  await axios.put<Response<BusinessProfile>>(
    API_ROUTES.accounts.business.profile.update,
    data,
  );

const getSettings = async () =>
  await axios.get<Response<BusinessSettings>>(
    API_ROUTES.accounts.business.settings.root,
  );

const updateSettings = async (data: GeneralSettingForm) => {
  const formdata = new FormData();
  const iconPromises: any[] = [];
  if (Array.isArray(data.coin)) {
    data.coin.map((coin, index) => {
      if (typeof coin.icon === 'string' && coin.icon.includes('assets/coins')) {
        const iconPromise = urlToBlob(coin.icon).then((blob) => {
          if (blob) {
            const parts = blob.type.split('/');
            formdata.append(
              `coins[${index}]icon`,
              blob,
              'icon.'.concat(parts[1]),
            );
          }
        });
        iconPromises.push(iconPromise);
      }
      formdata.append(`coins[${index}]is_active`, coin.is_active ? '1' : '0');
      formdata.append(`coins[${index}]name`, coin.name);
      formdata.append(`coins[${index}]id`, String(coin.id));
    });
  }

  // Wait for all icon fetch promises to resolve before proceeding
  await Promise.all(iconPromises);

  formdata.append('settings.customer_language', String(data.customer_language));
  formdata.append('settings.business_language', String(data.business_language));
  formdata.append('settings.currency', String(data.currency));

  return await axios.patch<Response<BusinessSettings>>(
    API_ROUTES.accounts.business.settings.update,
    formdata,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

export default {
  getBusinessProfile,
  GetSocialLinks,
  UploadAvatar,
  UpdateGeneralDetails,
  UpdateBusinessSocialLinks,
  UpdateLocation,
  updateBusinessProfile,
  getSettings,
  updateSettings,
};
