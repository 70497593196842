import { IconProps } from '../@types/icon';

const CheckIcon = ({
  color = '#000000',
  width = 20,
  height = 20,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99999 18.75C14.7174 18.75 18.5417 14.9257 18.5417 10.2083C18.5417 5.49086 14.7174 1.66663 9.99999 1.66663C5.28256 1.66663 1.45833 5.49086 1.45833 10.2083C1.45833 14.9257 5.28256 18.75 9.99999 18.75ZM13.7185 8.37521C13.9717 8.04745 13.9113 7.57643 13.5836 7.32316C13.2558 7.06989 12.7848 7.13028 12.5315 7.45804L9.48871 11.3958L7.59012 9.65543C7.28478 9.37553 6.81036 9.39616 6.53046 9.7015C6.25057 10.0068 6.2712 10.4813 6.57653 10.7612L9.07653 13.0528C9.23123 13.1946 9.43827 13.2651 9.64736 13.2472C9.85645 13.2293 10.0485 13.1246 10.1768 12.9585L13.7185 8.37521Z"
      fill={color}
    />
  </svg>
);

export default CheckIcon;
