import { useTranslation } from 'react-i18next';
// config
import { allLangs, defaultLang } from '../config';
import { ThemeLanguage } from 'components/settings/type';

// ----------------------------------------------------------------------

const useLocales = () => {
  const { i18n, t: translate } = useTranslation();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: ThemeLanguage) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => String(translate(text, options)),
    currentLang,
    allLangs,
  };
};

export default useLocales;
