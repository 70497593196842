export type ERROR_TYPE = {
  key: string;
  code: string | number;
  message: string;
};

const API_ERROR_LIST: ERROR_TYPE[] = [
  { key: 'HTTP_400_BAD_REQUEST', code: 400, message: 'bad request' },
  { key: 'HTTP_401_UNAUTHORIZED', code: 401, message: 'unauthorized' },
  { key: 'HTTP_402_PAYMENT_REQUIRED', code: 402, message: 'payment required' },
  { key: 'HTTP_403_FORBIDDEN', code: 403, message: 'forbidden' },
  { key: 'HTTP_404_NOT_FOUND', code: 404, message: 'api not exist' },
  { key: 'HTTP_405_METHOD_NOT_ALLOWED', code: 405, message: '' },
  { key: 'HTTP_406_NOT_ACCEPTABLE', code: 406, message: '' },
  { key: 'HTTP_408_REQUEST_TIMEOUT', code: 408, message: 'request timeout' },
  { key: 'HTTP_413_REQUEST_ENTITY_TOO_LARGE', code: 413, message: '' },
  { key: 'HTTP_414_REQUEST_URI_TOO_LONG', code: 414, message: '' },
  { key: 'HTTP_415_UNSUPPORTED_MEDIA_TYPE', code: 415, message: '' },
  { key: 'HTTP_429_TOO_MANY_REQUESTS', code: 429, message: 'too many request' },
  { key: 'HTTP_431_REQUEST_HEADER_FIELDS_TOO_LARGE', code: 431, message: '' },
  { key: 'SERVER_ERROR', code: 500, message: 'Server error' },
  {
    key: 'HTTP_NETWORK_PROBLEM',
    code: 'ERR_NETWORK',
    message: 'Ops! \n you have network problem',
  },
  {
    key: 'WRONG_REQUEST',
    code: 'wrong-request-5a3d-f1a65b992d21',
    message: 'server not responed',
  },
  {
    key: 'SERVER_NOT_RESPOND',
    code: 'server-not-respond-5a3d-f1a65b992d21',
    message: 'server not responed',
  },
  {
    key: 'USER_DOES_NOT_EXIST',
    code: '7c88066b-5a3d-485c-9647-f1a65b992d21',
    message: 'No user found with this information',
  },
  {
    key: 'USER_ALREADY_EXIST',
    code: '598ee258-f2be-4d65-a9a6-7d148b3712a4',
    message: 'user with this information is already exist',
  },
  {
    key: 'USER_PERMISSION_DENIED',
    code: '4cf35bce-f3a9-417f-8b00-eb3b6630a0bb',
    message: 'You do not have permission to perform this action',
  },
  {
    key: 'USER_WRONG_AUTHENTICATED',
    code: 'a31a31b9-774e-4ea5-beaa-7dcb95b1568a',
    message: 'login with wrong information',
  },
  {
    key: 'USER_PASSWORDS_DONT_MATCH',
    code: 'ffd87879-1f76-40e8-8912-e54248b8d227',
    message: '',
  },
  {
    key: 'USER_WRONG_PASSWORD',
    code: '9506e491-cd67-4f0e-b001-dbd3b3e84080',
    message: '',
  },
  {
    key: 'USER_ACCESS_TOKEN_FAIL',
    code: '12e347a2-7ac8-47e3-b2e0-96521459573a',
    message: '',
  },
  {
    key: 'USER_UNIQUE_CONSTRAINT',
    code: '2dd31524-99a9-40a1-989e-c75a11295b93',
    message: '',
  },
  {
    key: 'USER_MUST_HAVE_MOBILE',
    code: 'a18737ea-e5ca-47f1-89d4-78bd8c41dc77',
    message: '',
  },

  {
    key: 'USER_EMAIL_MUST_BE_UNIQUE',
    code: '3e4bd4c7-01f4-4f28-91dd-93652fafc1e9',
    message: 'this email address already exist',
  },
  {
    key: 'USER_MOBILE_MUST_BE_UNIQUE',
    code: 'fd5ff3eb-e8bf-4d07-aeca-b61e9432d047',
    message: 'User Mobile must be unique!',
  },

  {
    key: 'USER_MOBILE_MUST_BE_VALID',
    code: '1e6bcd54-31dd-4425-950a-ee02f458fdff',
    message: 'User Mobile must be valid!',
  },
  {
    key: 'TOKEN_REFRESH_WRONG',
    code: '98dd0426-133f-40d6-9cdd-eccfc1d1e69a',
    message: '',
  },
  {
    key: 'TOKEN_ACCESS_WRONG',
    code: '279a593e-24ee-4b07-ac74-47b7d369238f',
    message: '',
  },
  {
    key: 'TOKEN_INVALID',
    code: 'cd9bbfaa-238a-4267-b1f0-da04ab196b6e',
    message: '',
  },

  {
    key: 'SOCIAL_MEDIA_LINK_WRONG',
    code: '704ee755-90a4-4d7a-bd27-b3c056e9a3ee',
    message: '',
  },
  {
    key: 'SOCIAL_MEDIA_USER_DOES_NOT_EXIST',
    code: '6939b2ea-e483-45f2-ae1d-d5547b6f743d',
    message: '',
  },

  {
    key: 'OTP_IS_WRONG',
    code: '9fb5d7b6-d262-4187-b679-b4d661110874',
    message: '',
  },
  {
    key: 'OTP_IS_ALREADY_SENT',
    code: 'f642b8cc-9529-4fac-819e-cc91437d71d3',
    message: '',
  },
  {
    key: 'OBJECT_DOES_NOT_EXIST',
    code: 'cf5fd012-97f6-43e2-9676-b70559db4fb7',
    message: 'Not found!',
  },
  {
    key: 'OBJECT_DOES_NOT_ACTIVE',
    code: '92991854-fd74-472a-85c2-b607c717fb9a',
    message: '',
  },
  {
    key: 'OBJECT_DOES_LIMITED',
    code: '83c54e31-9874-4d90-a039-dbaa4cc16860',
    message: '',
  },
  {
    key: 'OBJECT_DOES_NOT_PERIOD',
    code: '46542678-332c-45a9-8e9a-d519102e7f31',
    message: '',
  },
  {
    key: 'OBJECT_DOES_MULTI_PURCHASE',
    code: '36eabd8e-0fd4-4e23-9607-978b321cf2de',
    message: '',
  },
  {
    key: 'TRANSACTION_USER_IS_NONE',
    code: '1ba3a6fb-7596-4263-a950-ce0ad99295e2',
    message: '',
  },
  {
    key: 'TRANSACTION_CONTENT_TYPE',
    code: '0fd5a2f1-77b1-46fe-8025-749a6ffea41f',
    message: '',
  },
  {
    key: 'TRANSACTION_REWARD_NONE',
    code: 'e800612c-98ab-47e6-950e-71019e347dbf',
    message: '',
  },
  {
    key: 'TRANSACTION_ACTION_NONE',
    code: '6236b4bc-9d53-431b-b4a0-51053919ea0c',
    message: '',
  },
  {
    key: 'TRANSACTION_ACTION_TRIGGER',
    code: '35cd3c9a-5ad2-4af9-a741-b3658ec2a44b',
    message: '',
  },
  {
    key: 'TRANSACTION_REWARD_TRIGGER',
    code: '5aaa8e34-b4ea-4614-80cc-c75260e85e1f',
    message: '',
  },
  {
    key: 'TRANSACTION_NOT_ENOUGH_SCORE',
    code: 'fcb1fe60-841f-4b75-8829-f9699659f417',
    message: '',
  },

  {
    key: 'PAGE_DOES_NOT_EXIST',
    code: '5337a1bc-8d69-4a4a-9d73-21da87cad201',
    message: '',
  },

  {
    key: 'REWARD_ERROR_SPEND_IS_ACTIVE',
    code: '7fb6eb4f-00af-468a-b76f-b08bb03212e5',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_MULTI_PURCHASE',
    code: '71909242-1345-49e3-90ec-28e8468e412b',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_LIMIT_NUMBER',
    code: '2a4d3b13-ed6a-4209-8edb-17e4e0f932f0',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_LIMIT_PURCHASE',
    code: '47729e21-cf16-4d15-83aa-c743b6ef3197',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_EXPIRE_AT',
    code: 'd7198906-75a1-4512-8453-69200a646d3a',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_START_AT',
    code: 'e90b167d-5d6a-451b-87bc-bd509d9ba709',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_TIER_POINT',
    code: '32536c40-ceac-47b1-ba8b-fef52de1ac65',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_SCORE_BALANCE',
    code: 'ecbeaffc-0f52-444c-a5e9-9beac52224f7',
    message: '',
  },
  {
    key: 'REWARD_ERROR_SPEND_EXPIRE_START',
    code: '4a66a105-8680-4f3c-a5e9-1cefb2e59ba6',
    message: '',
  },
  {
    key: 'POINTSYSTEM_DOES_NOT_EXIST',
    code: 'de9b4066-01cf-4572-9753-204ec863a465',
    message: '',
  },
  {
    key: 'USER_CANT_CLAIM_REWARD',
    code: 'b48e408b-74ce-4b55-ab57-7aeb6b0f5888',
    message: '',
  },
  {
    key: 'ACTION_ERROR_SPEND_MULTI_CALCULATE',
    code: '0ef731a7-a69e-43f6-9293-d9b6e6304deb',
    message: '',
  },
  {
    key: 'ACTION_ERROR_POINT_TRIGGER_404',
    code: '2c8d0d25-493a-4a9d-9ba2-c80125e79012',
    message: '',
  },
  {
    key: 'SLOT_ERROR_VALIDATION',
    code: 'a3d69a34-0a27-4b07-86bc-674a78d37064',
    message: '',
  },
  {
    key: 'REWARD_IS_NOT_PUBLIC',
    code: 'fc9ec1ec-9fcf-4f9e-8e85-37d151ed0846',
    message: 'Private rewards should contain at least one rule',
  },
  {
    key: 'VOUCHER_CODE_METHOD',
    code: 'acf20ec8-32de-433f-9ffe-1ec6307b16c7',
    message: 'voucher code could not be empty',
  },
  {
    key: 'VOUCHER_CODE_LENGTH',
    code: 'c8d2ec0d-586b-4a2f-8dca-2e1f1ea20f98',
    message: 'VOUCHER_CODE_LENGTH',
  },
  {
    key: 'POINTSYSTEM_ERROR_SPEND_IS_ACTIVE',
    code: 'e977e285-3156-4c07-89f1-76db4783b89f',
    message: '',
  },
  {
    key: 'POINTSYSTEM_ERROR_SPEND_MULTI_CALCULATE',
    code: '0ef731a7-a69e-43f6-9293-d9b6e6304deb',
    message: '',
  },
  {
    key: 'POINTSYSTEM_ERROR_POINT_TRIGGER_404',
    code: '2c8d0d25-493a-4a9d-9ba2-c80125e79012',
    message: '',
  },
  {
    key: 'INSTAGRAM_DOES_NOT_EXIST',
    code: '96f5c0ff-8971-4984-be9d-4a1f6099e8af',
    message: 'INSTAGRAM_DOES_NOT_EXIST',
  },
  {
    key: 'INSTAGRAM_MEDIA_LINK_NOT_VALID',
    code: 'a03efb87-0845-47ec-8e71-49b4ebe46d00',
    message: 'INSTAGRAM_MEDIA_LINK_NOT_VALID',
  },
  {
    key: 'INSTAGRAM_TOKEN_IS_NOT_VALID',
    code: '857af58a-4efa-4622-9e37-f13b31129506',
    message: 'INSTAGRAM_TOKEN_IS_NOT_VALID',
  },
  {
    key: 'MACHINE_NOT_ACTIVE',
    code: 'd1e27935-df28-44d8-8276-a77df37de4b4',
    message: 'MACHINE_NOT_ACTIVE',
  },
  {
    key: 'USER_DOES_NOT_HAVE_ENOUGH_COINS',
    code: '8a22a0d9-7999-4d35-af73-b02972ec6efb',
    message: '',
  },
  {
    key: 'MACHINE_OUT_OF_DATE',
    code: '0aedc1fb-38f8-42b7-83da-55b2c57d3acb',
    message: '',
  },
  {
    key: 'MACHINE_USER_SPIN_LIMIT',
    code: '77b63a74-3356-4b8c-ae65-03b5707fdb7f',
    message: '',
  },
  {
    key: 'MACHINE_USER_SPIN_DELAY_LIMIT',
    code: 'eba4c522-029c-4859-9442-74b810eb4b24',
    message: '',
  },
  {
    key: 'MACHINE_REWARD_SHOULD_BE_AT_LEAST_3',
    code: '0a7eaa0c-d927-4383-b6a8-3f61b0799d21',
    message: 'MACHINE_REWARD_SHOULD_BE_AT_LEAST_3',
  },
  {
    key: 'XP_LIMIT_IS_6',
    code: 'ef9ed9d2-fd97-4538-8d8d-8cabcf26ce70',
    message: '',
  },
  {
    key: 'SOMETHING_WENT_WRONG',
    code: '7b4e5c58-ad91-428f-bd58-708ff29714a6',
    message: '',
  },
  {
    key: 'END_DATE_MUST_BE_GREATER_THAN_START_DATE',
    code: '2ccf1ab9-8388-413c-9614-205d3edcba2a',
    message: '',
  },
  {
    key: 'INTEGRATION_IS_NOT_SET',
    code: 'caa0135b-b8d0-45d4-835a-7ed8b3e5a36d',
    message: 'Integration is not set',
  },
  { key: 'MACHINE_REWARD_SHOULD_BE_AT_LEAST_2', code: '', message: '' },
  { key: 'MAX_FILE_UPLOAD_SIZE', code: '', message: '' },
  {
    key: 'USER_MOBILE_SHOULD_NOT_BE_EMPTY',
    code: '2e7d0c84-4e63-489d-aa9f-f5c8a86b13ef',
    message: '',
  },
  {
    key: 'USER_EMAIL_SHOULD_NOT_BE_EMPTY',
    code: '7a5e6d2f-0ca4-4c18-9af7-68e92b1f3c4d',
    message: '',
  },
  {
    key: 'USER_IS_NOT_ACTIVE',
    code: '8a5e1b2f-9a4d-4c38-af9c-7e2d6c0f18a5',
    message: '',
  },
  {
    key: 'AUTH_METHOD_INVALID',
    code: 'e49c8d0a-7b4e-4f2c-9a5f-0c1e8a3f697d',
    message: '',
  },
  {
    key: 'OBJECT_DOES_ALREADY_EXIST',
    code: '32e1ca60-2930-4326-ba28-2a58e3bab35b',
    message: '',
  },
  {
    key: 'REWARD_LIMIT_IS_NOT_ENOUGH',
    code: 'e3dc55c3-9742-42e6-82f5-60e7b8ecd491',
    message: '',
  },
  {
    key: 'REWARD_SCORE_NOT_ENOUGH',
    code: '1989a5b5-2d27-4a2a-97ce-27f1a2b96370',
    message: '',
  },
  {
    key: 'REWARD_ORDERING_DOES_NOT_EXIST',
    code: '0371813c-02b1-4122-ae37-bd549b54cb27',
    message: '',
  },
  {
    key: 'REWARD_VOUCHER_OBJ_OR_VALIDATED_DATA_DOES_NOT_EXIST',
    code: '8f6a6707-f1d3-4bd3-9ade-dc782011972c',
    message: '',
  },
  {
    key: 'OBJECT_SHOULD_BE_REWARD_VOUCHER',
    code: '5853f40d-7163-4968-86cb-421ef226f121',
    message: '',
  },
  {
    key: 'USER_DOES_NOT_EXIST_IN_TIERSYSTEM',
    code: 'a1e0c8d6-3e4b-49cf-95d9-7f89038b81e7',
    message: '',
  },
  {
    key: 'DURATION_REQUIREMENT_NOT_MET',
    code: '6d45c8e2-9d7f-4e42-8951-af0a3cfe586d',
    message: '',
  },
  {
    key: 'REWARD_IS_NOT_PURCHASABLE',
    code: 'a8c3d1f6-4b5e-48fc-9d9a-2f1e8a7906cd',
    message: '',
  },
  {
    key: 'VOUCHER_CODE_IS_NOT_AVAILABLE',
    code: 'f2b81cbc-fde3-4a66-9309-00312ef42490',
    message: '',
  },
  {
    key: 'ACTION_NOT_IN_MISSION',
    code: '3b9e44cd-7b92-4f21-a10e-96566c4a9e73',
    message: '',
  },
  {
    key: 'MISSION_IS_REQUIRED',
    code: '39b5a78f-059c-4359-bbcf-6c4612bf65ca',
    message: '',
  },
  {
    key: 'WHMCS_STATUS_CODE_IS_NOT_SUCCESS',
    code: '4409c32b-2f05-495c-a282-481657f08706',
    message: '',
  },
  {
    key: 'WHMCS_SERVER_DOSE_NOT_WORK',
    code: 'fb5e6462-e163-4587-839f-09ed31445d65',
    message: '',
  },
  {
    key: 'WORDPRESS_SERVER_DOSE_NOT_WORK',
    code: 'e9f1d8a0-57b4-4c1f-8a9a-492146a7e9b',
    message: '',
  },
  {
    key: 'INSTAGRAM_CONNECTION_ERROR',
    code: '1074d21b-216f-4f95-ba64-c96f8d6271ff',
    message: 'INSTAGRAM_CONNECTION_ERROR',
  },
  {
    key: 'INSTAGRAM_CREDENTIALS_NOT_COMPLETE',
    code: '96f5c0ff-8971-4984-be9d-4a1f6099e8af',
    message: 'INSTAGRAM_CREDENTIALS_NOT_COMPLETE',
  },
  {
    key: 'GOOGLE_ANALYTICS_LENGTH_PROPERTY_ID',
    code: '105d0c98-499d-4f39-9435-983961c67cde',
    message: 'GOOGLE_ANALYTICS_LENGTH_PROPERTY_ID',
  },
  {
    key: 'CREDENTIAL_FILE_IS_NOT_EXISTS',
    code: '1074d21b-216f-4f95-ba64-c96f8d6271fa',
    message: 'CREDENTIAL_FILE_IS_NOT_EXISTS',
  },
  {
    key: 'JUST_ONE_PROVIDER_ALLOWED',
    code: '0e2787d5-7131-4948-ac8e-6dffff075f73',
    message: 'JUST_ONE_PROVIDER_ALLOWED',
  },
  {
    key: 'INVALID_GOOGLE_AUTH_FILE',
    code: 'cd249b64-7add-4269-b335-a55020d6c590',
    message: 'INVALID_GOOGLE_AUTH_FILE',
  },
  {
    key: 'INVALID_GOOGLE_SERVICE_FILE',
    code: '0c42d637-7516-402a-bb95-de63acae1875',
    message: 'INVALID_GOOGLE_SERVICE_FILE',
  },
  {
    key: 'INVALID_MISSION_DATE',
    code: '639ded6a-0595-4679-bc72-4d2f48c86297',
    message: 'INVALID_MISSION_DATE',
  },
];

export function GetErrorType(value: string) {
  return Object.keys(API_ERROR_LIST).find(
    (key: any) => API_ERROR_LIST[key].key === value,
  );
}

export function ErrorMessage(value: string | number): ERROR_TYPE {
  const index: any = Object.keys(API_ERROR_LIST).find(
    (key: any) => API_ERROR_LIST[key].code === value,
  );
  return API_ERROR_LIST[index];
}

export function APIErrorHandler(err: any) {
  console.log(err);
}

export default API_ERROR_LIST;
