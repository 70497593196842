import { atom } from 'jotai';
import { enUS, faIR } from '@mui/material/locale';

import { BusinessSettings } from '@/types/business';
import { LanguagesType, PanelSetting } from '@/types/setting.type';

export const Languages: Array<LanguagesType> = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/flags/en.svg',
  },
  {
    label: 'فارسی',
    value: 'fa',
    systemValue: faIR,
    icon: '/assets/flags/ir.svg',
  },
];

export const AtomSettingState = atom<PanelSetting>({
  isLoading: false,
  name: '',
  logo: '',
  language: Languages[0],
  business_language: Languages[0],
});

export const settingAtom = atom<BusinessSettings>({
  settings: {
    integration: 1,
    currency: 1,
    currency_symbol: '$',
    business_language: 0,
    customer_language: 1,
    reporting_rate: 1,
    auth_method: 0,
    is_first_fender_after_login: false,
    google_analytics: false,
    integrationData: {
      integrationconfiguration: {
        custom: {
          api_key: '',
          is_active: false,
        },
        whmcs: {
          api_key: '',
          is_active: false,
        },
        wordpress: {
          api_key: '',
          is_active: false,
        },
      },
      notificationconfigurations: {
        sms: {
          farapayamak: {
            is_active: false,
            password: '',
            sender_numbers: null,
            username: '',
          },
          kavenegar: {
            api_key: '',
            is_active: false,
            template_name: '',
            sender_numbers: [],
          },
        },
        email: {
          smtp: {
            host: '',
            is_active: false,
            password: '',
            port: 0,
            ssl: false,
            tls: false,
            user_host: '',
          },
          mailgun: {
            api_key: null,
            from_email: null,
            from_name: null,
            domain_name: null,
            is_active: false,
          },
        },
      },
      googleconfiguration: {
        auth: {
          auth_provider_x509_cert_url: '',
          auth_uri: '',
          client_id: '',
          client_secret: '',
          file_path: '',
          is_active: false,
          project_id: '',
          redirect_uris: [],
          token_uri: '',
        },
        credentials: {
          auth_provider_x509_cert_url: '',
          auth_uri: '',
          client_email: '',
          client_id: '',
          client_x509_cert_url: '',
          file_path: '',
          google_analytics: {
            is_active: false,
            property_id: null,
          },
          google_form: {
            is_active: false,
          },
          private_key: '',
          private_key_id: '',
          project_id: '',
          token_uri: '',
          type: '',
          universe_domain: '',
        },
      },
    },
  },
  coins: [],
});
