import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// config
import { defaultLang } from '../config';
//
import { ThemeLanguage } from 'components/settings/type';

import enTranslate from './en';
import faTranslate from './fa';

// ----------------------------------------------------------------------

const initalI18n = (lang: ThemeLanguage) => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: { translations: enTranslate },
        fa: { translations: faTranslate },
      },
      lng: lang || defaultLang.value,
      fallbackLng: defaultLang.value,
      debug: false,
      ns: ['translations'],
      defaultNS: 'translations',
      interpolation: {
        escapeValue: false,
      },
    });
};

export default initalI18n;
