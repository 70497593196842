import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Tabs = (theme: Theme) => ({
  // MuiTab: {
  //   defaultProps: {},
  //   styleOverrides: {
  //     // root: {},
  //     // labelIcon: {},
  //     wrapper: {
  //       flexDirection: 'row',
  //       whiteSpace: 'nowrap',
  //     },
  //     textColorInherit: {
  //       opacity: 1,
  //     },
  //   },
  // },
  MuiTabs: {
    //     defaultProps: {},
    styleOverrides: {
      root: {
        //   // display: 'none',
      },
      //     flexContainer: {
      //       backgroundColor: 'green',
      //     },
      //     // },
    },
  },
  //   MuiTabPanel: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: 'red',
  //         padding: 0,
  //       },
  //     },
  //   },
  //   MuiTabScrollButton: {
  //     styleOverrides: {
  //       root: {
  //         width: 48,
  //         borderRadius: '50%',
  //       },
  //     },
  //   },
});

export default Tabs;
