import { Color } from '@mui/material';

const RED: Color = {
  50: '#FEF2F2',
  100: '#FEE2E2',
  200: '#FECACA',
  300: '#FCA5A5',
  400: '#F87171',
  500: '#EF4444',
  600: '#DC2626',
  700: '#B91C1C',
  800: '#991B1B',
  900: '#7F1D1D',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default RED;
