// ----------------------------------------------------------------------

import { NotificationType } from '@/types/notification';
import { RewardType } from '../@types/reward';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    experienceManager: path(ROOTS_DASHBOARD, '/user-managment'),
  },
  rewards: {
    root: path(ROOTS_DASHBOARD, '/rewards'),
    create: path(ROOTS_DASHBOARD, `/rewards/create`),
    duplicateVoucher: (id: number) => `/dashboard/rewards/create/${id}/voucher`,
    edit: (id: number, type: RewardType, subpath?: 'code') => {
      let fullpath = `/rewards/edit/${type}/${id}`;
      if (subpath) {
        fullpath += `/code`;
      }
      return path(ROOTS_DASHBOARD, fullpath);
    },
  },
  customers: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    create: path(ROOTS_DASHBOARD, '/customers/create'),
    edit: (id: string | number) =>
      path(ROOTS_DASHBOARD, `/customers/edit/${id}`),
    view: (id: string | number, route: string = 'points-history') =>
      path(ROOTS_DASHBOARD, `/customers/profile/${id}/${route}`),
    // TODO: wtf! refactor. Use indented paths. (children)
    points: {
      addel: (id: string) =>
        path(
          ROOTS_DASHBOARD,
          `/customers/profile/${id}/overview/points/add-delete`,
        ),
    },
    segments: {
      root: path(ROOTS_DASHBOARD, '/customers/segments'),
      create: path(ROOTS_DASHBOARD, '/customers/segments/create'),
      detail: (id: string | number) =>
        path(ROOTS_DASHBOARD, `/customers/segments/detail/${id}`),
      edit: (id: string | number) =>
        path(ROOTS_DASHBOARD, `/customers/segments/edit/${id}`),
    },
  },
  offers: {
    root: path(ROOTS_DASHBOARD, '/offers'),
  },
  coins: {
    root: path(ROOTS_DASHBOARD, '/coins'),
    create: path(ROOTS_DASHBOARD, '/coins/create'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/coins/edit/${id}`),
  },
  tiers: {
    root: path(ROOTS_DASHBOARD, '/tiers'),
    breakdown_guides: path(ROOTS_DASHBOARD, '/tiers/breakdown_guides'),
  },
  notification: {
    root: path(ROOTS_DASHBOARD, '/notifications'),
    create: path(ROOTS_DASHBOARD, '/notifications/create'),
    edit: (id: number, type: NotificationType, subpath?: 'statistics') => {
      let fullpath = `/notifications/edit/${type}/${id}`;
      if (subpath) {
        fullpath += `/statistics`;
      }
      return path(ROOTS_DASHBOARD, fullpath);
    },
    // edit: (id: number) => path(ROOTS_DASHBOARD, `/notifications/edit/${id}`),
  },
  leaderboard: {
    root: path(ROOTS_DASHBOARD, '/gamification/leaderboard'),
  },
  integrations: {
    root: path(ROOTS_DASHBOARD, '/integrations'),
  },

  invoiceAndProduct: {
    root: path(ROOTS_DASHBOARD, '/business-data/invoice'),
    product: path(ROOTS_DASHBOARD, '/business-data/product'),
  },

  club: {
    content: path(ROOTS_DASHBOARD, '/club/content'),
    style: path(ROOTS_DASHBOARD, '/club/style'),
    illustrations: path(ROOTS_DASHBOARD, '/club/illustrations'),
  },

  games: {
    root: path(ROOTS_DASHBOARD, '/games'),
    create: path(ROOTS_DASHBOARD, '/games/create'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/games/edit/${id}`),
  },
  affiliate: {
    root: path(ROOTS_DASHBOARD, '/affiliate'),
    report: path(ROOTS_DASHBOARD, '/affiliate/report'),
    settings: path(ROOTS_DASHBOARD, '/affiliate/settings'),
  },
  gamification: {
    missions: {
      root: path(ROOTS_DASHBOARD, '/gamification/missions'),
      create: path(ROOTS_DASHBOARD, '/gamification/missions/create'),
      edit: (id: number) =>
        path(ROOTS_DASHBOARD, `/gamification/missions/edit/${id}`),
    },
    preMissions: {
      root: path(ROOTS_DASHBOARD, '/gamification/predefined-mission'),
      create: path(ROOTS_DASHBOARD, '/gamification/predefined-mission/create'),
      edit: (id: number) =>
        path(ROOTS_DASHBOARD, `/gamification/predefined-mission/edit/${id}`),
    },
    actions: {
      root: path(ROOTS_DASHBOARD, '/gamification/actions'),
      create: path(ROOTS_DASHBOARD, '/gamification/actions/create'),
      edit: (id: number) =>
        path(ROOTS_DASHBOARD, `/gamification/actions/edit/${id}`),
    },
    flows: {
      root: path(ROOTS_DASHBOARD, '/gamification/flows'),
      create: path(ROOTS_DASHBOARD, '/gamification/flows/create'),
      edit: (id: number) =>
        path(ROOTS_DASHBOARD, `/gamification/flows/edit/${id}`),
    },
  },
  referrals: {
    root: path(ROOTS_DASHBOARD, '/referrals'),
    create: path(ROOTS_DASHBOARD, '/referrals/create'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/referrals/edit/${id}`),
  },
  tasks: {
    root: path(ROOTS_DASHBOARD, '/tasks'),
    // TODO we can seperate the suffix and reuse it in the routing
    bulk_points: {
      root: path(ROOTS_DASHBOARD, '/tasks/bulk-points'),
      create: path(ROOTS_DASHBOARD, '/tasks/bulk-points/create'),
    },
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    management: {
      userList: path(ROOTS_DASHBOARD, '/user-management/user-list'),
      newUser: path(ROOTS_DASHBOARD, '/user-management/user-list/new-user'),
      edit: (username: string) =>
        path(ROOTS_DASHBOARD, `/user-management/user-list/edit/${username}`),
      userLog: path(ROOTS_DASHBOARD, '/user-management/user-log'),
      generalSetting: path(ROOTS_DASHBOARD, '/user-management/general-setting'),
      appSetting: path(ROOTS_DASHBOARD, '/user-management/app-setting'),
      businessProfile: path(
        ROOTS_DASHBOARD,
        '/user-management/business-profile',
      ),

      customerSideBranding: path(
        ROOTS_DASHBOARD,
        '/user-management/customer-branding',
      ),
      role: {
        userRoles: path(ROOTS_DASHBOARD, '/user-management/roles'),
        newRole: path(ROOTS_DASHBOARD, '/user-management/roles/new-role'),
        editRole: (id: string | number) =>
          path(ROOTS_DASHBOARD, `/user-management/roles/${id}`),
      },
    },
  },
  journeys: {
    list: path(ROOTS_DASHBOARD, `/journey/list`),
    create: path(ROOTS_DASHBOARD, `/journey/create`),
    editSettings: (id: number) =>
      path(ROOTS_DASHBOARD, `/journey/edit/${id}/settings`),
    editFlows: (id: number) =>
      path(ROOTS_DASHBOARD, `/journey/edit/${id}/flows`),
    statistics: (id: number) =>
      path(ROOTS_DASHBOARD, `/journey/edit/${id}/statistics`),
  },
};
