import { red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Radio = (theme: Theme) => ({
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1),
        color: theme.palette.grey[300],
        svg: {
          fontSize: 24,
          '&[font-size=small]': {
            fontSize: 20,
          },
        },
      },
    },
  },
});

export default Radio;
