import { useQuery } from '@tanstack/react-query';

import { Response } from '../@types/api';
import API_ROUTES from '../api_endpints';
import api from '../utils/axios';
import { QueryStaleTime } from 'utils/queryClient';
import {
  GoogleAnalyticsFormType,
  Instagram,
  IntegrationsGoogleAnalyticsCredentialsApi,
  SerializedEmail,
  SmsFarapayamakIntegrationSerialized,
  SmsIntegrationSerialized,
} from '@/types/integrations';
import { Configuration } from '@/types/business';

const whmcsTestConnection = async () =>
  api.get(API_ROUTES.accounts.business.integrations.whmcs.testConnection);

const getGoogleAnalyticsCredentials = async () =>
  api.get<Response<IntegrationsGoogleAnalyticsCredentialsApi>>(
    API_ROUTES.accounts.business.integrations.googleAnalytics.detail,
  );

const useGetGoogleAnalyticsCredentials = () => {
  return useQuery(
    ['GoogleAnalyticsCredentialsDetails'],
    () => getGoogleAnalyticsCredentials(),
    {
      staleTime: QueryStaleTime,
    },
  );
};

const createGoogleAnalyticsCredentials = ({
  data,
}: {
  data: GoogleAnalyticsFormType;
}) => {
  const formData = new FormData();
  formData.append('property_id', String(data.property_id));
  formData.append('credentials', data.credentials);
  return api.postForm<Response<IntegrationsGoogleAnalyticsCredentialsApi>>(
    API_ROUTES.accounts.business.integrations.googleAnalytics.create,
    formData,
  );
};

const updateGoogleAnalyticsCredentials = ({
  data,
}: {
  data: GoogleAnalyticsFormType;
}) => {
  const formData = new FormData();
  data.property_id && formData.append('property_id', String(data.property_id));
  data.credentials && formData.append('credentials', data.credentials);
  return api.patchForm<Response<IntegrationsGoogleAnalyticsCredentialsApi>>(
    API_ROUTES.accounts.business.integrations.googleAnalytics.update,
    formData,
  );
};

const googleService = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post<any>(
    API_ROUTES.accounts.business.integrations.googleService,
    formData,
  );
};

const googleAuth = (data: { file: File; is_active: boolean }) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('is_active', data.is_active.toString());

  return api.put<any>(
    API_ROUTES.accounts.business.integrations.googleAuth,
    formData,
  );
};

const googleAnalytic = (data: { property_id: string; is_active: boolean }) => {
  return api.put<any>(
    API_ROUTES.accounts.business.integrations.googleAnalytic,
    data,
  );
};

const googleForms = (data: { is_active: boolean }) => {
  return api.put<any>(
    API_ROUTES.accounts.business.integrations.googleForm,
    data,
  );
};

const getAllIntegrations = () => {
  return api.get<Response<Configuration>>(
    API_ROUTES.accounts.business.settings.integration,
  );
};

const updateSMS = (
  data: SmsIntegrationSerialized | SmsFarapayamakIntegrationSerialized,
) => {
  return api.put(API_ROUTES.accounts.business.integrations.sms, data);
};

const updateEmail = (data: SerializedEmail) => {
  return api.put(API_ROUTES.accounts.business.integrations.email, data);
};

const getInstagram = () => {
  return api.get<Response<Instagram>>(
    API_ROUTES.accounts.business.integrations.getInstagram,
  );
};

const createInstagram = (data: Instagram) => {
  return api.post<Response<Instagram>>(
    API_ROUTES.accounts.business.integrations.createInstagram,
    data,
  );
};

const updateInstagram = (data: Partial<Instagram>) => {
  return api.patch<Response<Instagram>>(
    API_ROUTES.accounts.business.integrations.updateInstagram,
    data,
  );
};

const activationMailgun = (email: string) =>
  api.post<Response>(
    API_ROUTES.accounts.business.integrations.activationMailGun,
    { email },
  );

const activationMailgunSubmit = (name: string, user_id: string) =>
  api.post<Response>(
    API_ROUTES.accounts.business.integrations.submitActivationMailgun(
      name,
      user_id,
    ),
  );

export default {
  getAllIntegrations,
  whmcsTestConnection,
  getGoogleAnalyticsCredentials,
  useGetGoogleAnalyticsCredentials,
  createGoogleAnalyticsCredentials,
  updateGoogleAnalyticsCredentials,
  googleAnalytic,
  googleService,
  googleAuth,
  googleForms,
  updateSMS,
  updateEmail,
  getInstagram,
  createInstagram,
  updateInstagram,
  activationMailgun,
  activationMailgunSubmit,
};
