import { createContext, ReactNode, useEffect, useReducer } from 'react';

import API_ROUTES from '../api_endpints';
import UserApi from '../api/user.api';
import AuthApi from '../api/auth.api';
import { BusinessProfile, UserProfile } from '../@types/user';
import { HOST_API } from '../config';
import { ActionMap, AuthState, JWTContextType } from '../@types/auth';
import setSession from '../utils/jwt';
import axios from '../utils/axios';
import { useAtomSetting } from 'stateManagement/hooks/useSetting';
import { useGetPanelSetting } from 'api/settings.api';

import { useAtomValue, useSetAtom } from 'jotai';
import { AtomSettingState, Languages } from 'stateManagement/atoms/settingAtom';
import initalI18n from 'locales/i18n';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  UpdateUser = 'UPDATE_USER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: UserProfile | null;
  };
  [Types.Login]: {
    isAuthenticated: boolean;
    profile: BusinessProfile | null;
    user: UserProfile | null;
  };
  [Types.Logout]: undefined;
  [Types.UpdateUser]: {
    user: UserProfile | null;
  };
  [Types.Register]: {
    user: UserProfile | null;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { setFirstRender } = useAtomSetting();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useGetPanelSetting();

  useEffect(() => {
    const initialize = async () => {
      if (!window.location.pathname.includes('auth')) {
        try {
          const accessToken = localStorage.getItem('accessToken');
          const refreshToken = localStorage.getItem('refreshToken');

          if (accessToken && refreshToken) {
            setSession(accessToken, refreshToken);

            const response = await axios.get(
              API_ROUTES.accounts.business.user.detail,
            );

            const { data } = response.data;

            if (response.status === 200) {
              if (data?.avatar) {
                data.avatar = HOST_API + data.avatar;
              }
              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: true,
                  user: data,
                },
              });
            } else {
              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
            }
          } else {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
    };
    initialize();
  }, []);

  const login = async (email: string, password: string) =>
    await AuthApi.login(email, password).then((response) => {
      if (response.status === 200) {
        const { access, refresh, profile, user } = response.data.data;
        setSession(access, refresh);
        setFirstRender();
        dispatch({
          type: Types.Login,
          payload: {
            isAuthenticated: true,
            user,
            profile,
          },
        });
      }
    });

  const updateUser = (user: UserProfile): void => {
    dispatch({
      type: Types.UpdateUser,
      payload: {
        user,
      },
    });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => {
    const response = await axios.post(API_ROUTES.register, {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      AuthApi.logout(accessToken);
    }
    setSession(null, null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
