import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Paper = (theme: Theme) => ({
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },

    variants: [
      {
        props: { variant: 'outlined' },
        style: { borderColor: alpha(theme.palette.grey[500], 0.12) },
      },
    ],

    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
});

export default Paper;
