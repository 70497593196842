import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const Card = (theme: Theme) => ({
  MuiCard: {
    styleOverrides: {
      root: {
        border: '1px solid #EBEDEF',
        position: 'relative',
        boxShadow: theme.customShadows.card,
        // borderRadius: theme.shape.borderRadius,
        zIndex: 0, // Fix Safari overflow: hidden with border radius
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: { variant: 'h6' },
      subheaderTypographyProps: {
        variant: 'body2',
        marginTop: theme.spacing(0.5),
      },
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 3, 0),
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3),
      },
    },
  },
});

export default Card;
