import { Button, Stack, Typography } from '@mui/material';
import Iconify from './Iconify';
import i18next from 'i18next';

const RequestProgressFailed = () => (
  <Stack
    sx={{ width: '100%', height: '100%' }}
    flexDirection="row"
    alignContent="center"
    justifyContent="center"
  >
    <Stack justifyContent="center">
      <Stack gap={2} alignItems={'center'}>
        <Typography>{i18next.t('common.something_wrong')}</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => window.location.reload()}
          startIcon={<Iconify icon="eva:sync-outline" />}
        >
          {i18next.t('common.try_again')}
        </Button>
      </Stack>
    </Stack>
  </Stack>
);

export default RequestProgressFailed;
