// components

import { HOST_API } from 'config';

const urlToFile = (url: string) => {
  console.log('url', url);
  return fetch(url).then(async (response) => {
    if (response.status === 200) {
      const filename = url.substring(url.lastIndexOf('/') + 1);
      const contentType = response.headers.get('content-type') || undefined;
      const blob = await response.blob();
      const file = new File([blob], filename, {
        type: contentType,
      });
      return file;
    }
    return undefined;
  });
};

export const urlToBlob = (url: string) =>
  fetch(url).then(async (response) => {
    if (response.status === 200) {
      return await response.blob();
    }
    return undefined;
  });

export const downloadImage = async (imageUrl: string) => {
  try {
    const filename = imageUrl.split('/').pop();
    const response = await fetch(`${HOST_API}${imageUrl}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const file = new File([blob], filename || 'app_icon', {
      type: blob.type,
    });
    return file;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
};

export default urlToFile;
