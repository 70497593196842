import { pxToRem, responsiveFontSizes } from '../../../utils/getFontValue';
import desingToken from './token';
import { GREY } from '../../desing_system/colors';
// import '../../fonts/plus-jakarta-sans/index.css';

// ----------------------------------------------------------------------

const FONT_EN = 'Plus Jakarta Sans, AzarMehr'; // Google Font
const FONT_FA = 'AzarMehr'; // Google Font
const typography = {
  en: {
    fontFamily: FONT_EN,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h4: {
      fontWeight: 700,
      fontSize: pxToRem(24),
    },
    h5: {
      fontWeight: 600,
      fontSize: pxToRem(18),
      color: '#182129',
    },
    h6: {
      fontWeight: 600,
      fontSize: pxToRem(16),
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: pxToRem(14),
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: pxToRem(14),
    },
    body1: {
      fontWeight: 500,
      fontSize: pxToRem(14),
    },
    body2: {
      fontWeight: 700,
      fontSize: pxToRem(12),
    },
    caption: {
      fontWeight: 500,
      fontSize: pxToRem(12),
    },
    overline: {
      fontWeight: 500,
      fontSize: pxToRem(12),
      textTransform: 'none',
    },
    button: {
      fontWeight: 600,
      fontSize: pxToRem(14),
      textTransform: 'none',
    },
  },
  fa: {
    fontFamily: FONT_FA,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      lineHeight: 80 / 64,
      fontSize: pxToRem(40),
      letterSpacing: 2,
    },
    h2: {
      fontWeight: 700,
      lineHeight: 64 / 48,
      fontSize: pxToRem(32),
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(24),
      color: '#182129',
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(20),
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      color: '#182129',
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(17),
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    body2: {
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      color: GREY[500],
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(desingToken.button_font_size),
      textTransform: 'none',
    },
  },
} as const;

export default typography;
